import React from "react";
import styled from "styled-components";

import { desktopBreakpoint } from "../styleBits";

const Card = styled.div<{ width: string }>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  height: fit-content;
  margin: 0 12px 24px;
  width: ${(props) => props.width};
  box-shadow: var(--testimonial-shadow);
  background: var(--testimonial-bg);
  color: var(--testimonial-text-color);

  p {
    margin: 0;
    padding: 0;
    display: inline;
  }

  .quote {
    position: relative;
    font-size: 18px;

    > div {
      display: inline;
    }

    &:before {
      content: "“";
      ${desktopBreakpoint} {
        position: absolute;
        left: -10px;
        top: 0;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  .author {
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const TestimonyCard = ({ testimonial, width }) => {
  return (
    <Card width={width}>
      <div className="quote">
        <p>{testimonial.text}</p>
        <span>&rdquo;</span>
      </div>
      <span className="author">&mdash; {testimonial.author}</span>
    </Card>
  );
};
