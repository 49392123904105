import React from "react";
import styled, { css } from "styled-components";

const SwitchContainer = styled.div`
  width: 40px;
  height: 24px;
  border-radius: 40px;
  background: ${(props) =>
    props.value ? "var(--brand-pink)" : "var(--timeline-border)"};
  position: relative;
  cursor: pointer;
  transition: background 0.2s;
`;

const SwitchToggle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--misc-white-heavy);
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.2s;
  ${(props) =>
    props.value &&
    css`
      left: 20px;
    `}
`;

const Switch = ({ value, onChange }) => {
  return (
    <SwitchContainer value={value} onClick={onChange}>
      <SwitchToggle value={value} />
    </SwitchContainer>
  );
};

export default Switch;
