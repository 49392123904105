import { API_URL } from "./api";

interface RequestTitle {
  title: string;
  language: string;
  email: string;
  streamer?: string;
  requestedVia?: string;
}

export const saveToRequestedTitles = async (data: RequestTitle) => {
  await fetch(`${API_URL}/request-title`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
