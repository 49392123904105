import React, { Component } from "react";
import styled from "styled-components";
import { ImageEnum, imgBlob } from "../cssImg";
import { component } from "../component";
import { state } from "../state";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 100%;
  height: 400px;
  background: #7d3c000a;
  padding: 40px 0;
  margin-top: 20px;

  .empty-message {
    font-family: "BryantWebBold";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: var(--empty-state-text);
  }

  .empty-details {
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: var(--empty-state-secondary);

    a {
      color: #ff0082;
    }
  }

  img {
    width: 160px;
    margin-bottom: 30px;
  }
`;

@component
export class StoriesEmptyState extends Component<{ search }> {
  render() {
    const search = this.props.search;
    const theme = state.theme === "high-contrast" ? "high" : state.theme;
    const images = {
      sad: {
        light: ImageEnum.sadLight,
        dark: ImageEnum.sadDark,
        high: ImageEnum.sadHigh,
      },
    };
    const sadImg = images.sad[theme];

    return (
      <Container>
        <img src={imgBlob(sadImg)} alt="No Results Found" />
        {search ? (
          <div className="empty-message">
            Good idea, but no results found :(
          </div>
        ) : (
          <div className="empty-message">Story Not Found :(</div>
        )}
        {search ? (
          <div className="empty-details">Try another search query!</div>
        ) : (
          <div className="empty-details">
            Check out our other stories <a href="/stories">here</a>
          </div>
        )}
      </Container>
    );
  }
}

export default StoriesEmptyState;
