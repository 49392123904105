import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { imgBlob } from "../cssImg";
import useStripePromise from "../hooks/useStripePromise";
import useTheme from "../hooks/useTheme";
import { getCompleteImage } from "../components/stripe/utils";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  padding-bottom: 64px;
  max-width: 1080px;
  min-height: calc(100vh - 444px);

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    font-family: "BryantWebBold";
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -3px;
    color: var(--donate-complete-h1);
    margin-bottom: 24px;
  }

  .complete-message {
    text-align: center;
    font-family: "BryantWebBold";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    color: var(--donate-complete-h1);
  }

  .complete-subtext {
    color: var(--donate-complete-h1);
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 40px;
  }

  a {
    color: var(--brand-pink);
  }
`;

function Completion() {
  const [theme] = useTheme();
  const stripePromise = useStripePromise();
  const [isError, setIsError] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [messageBody, setMessageBody] = useState("");
  const image = getCompleteImage(theme, isError);

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const url = new URL(window.location as any);
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      const recurring = sessionStorage.getItem(clientSecret);
      if (recurring === "true") {
        setIsRecurring(true);
      }
      sessionStorage.removeItem(clientSecret);

      const { error } = await stripe.retrievePaymentIntent(clientSecret);
      setIsError(!!error);
      setMessageBody(error ? error.message : "Thank you!");
    });
  }, [stripePromise]);

  return (
    <Container>
      <img src={imgBlob(image)} alt="Giving" width="200" height="200" />
      {isError ? (
        <>
          <h1>Oops! Something went wrong.</h1>
          <div className="complete-message" role="alert">
            {messageBody}
          </div>
        </>
      ) : (
        <>
          <h1>Donation successful</h1>
          <div className="complete-message">
            Thank you for your support! <a href="/">Go Home</a>
          </div>
          {isRecurring ? (
            <div className="complete-subtext">
              Please email SignUp at{" "}
              <a href="mailto:signupcaptions@gmail.com">
                signupcaptions@gmail.com
              </a>{" "}
              to cancel your recurring donation.
            </div>
          ) : null}
        </>
      )}
    </Container>
  );
}

export default Completion;
