import { Lambda } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

export async function sendEmail(args) {
  const credentials = fromCognitoIdentityPool({
    region: "us-east-1",
    clientConfig: {
      region: "us-east-1",
    },
    identityPoolId: "us-east-1:d87d168d-bb38-4311-9037-b441208aa506",
  } as any);

  const lambda = new Lambda({
    region: "us-east-1",
    credentials,
  });

  await lambda.invoke({
    FunctionName: "arn:aws:lambda:us-east-1:425167100516:function:sendEmail",
    Payload: JSON.stringify(args) as any,
  });

  return true;
}
