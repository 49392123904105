import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import format from "date-fns/format";

const MoreStoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
`;

const StoryPreview = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--stories-border);
  border-radius: 16px;
  padding: 24px;
`;

const StoryTitle = styled.h2`
  width: 100%;
  color: var(--title);
  font-family: "BryantWebBold";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1px;
`;

const StoryDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: var(--subtitle);

  .date {
    color: var(--timeline-anchor);
  }
`;

const StoryContent = styled.div`
  font-family: "BryantWebBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: var(--subtitle);
`;

const ReadMoreTitle = styled.div`
  margin-top: 40px;
  font-family: "BryantWebBold";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1px;
  color: var(--subtitle);
`;

const MoreStories = ({ current, stories }) => {
  const filteredStories = stories
    .filter((story) => story._id !== current._id)
    .slice(0, 3);

  if (filteredStories.length === 0) return null;

  return (
    <MoreStoriesContainer>
      <ReadMoreTitle>Read More Stories</ReadMoreTitle>
      {filteredStories.map((story) => (
        <Link to={`/story/${story._id}`} key={story._id}>
          <StoryPreview>
            <StoryTitle>{story.title}</StoryTitle>
            <StoryDetails>
              <span>By {story.author}</span>
              <span className="date">
                {story.date ? format(new Date(story.date), "MM/dd/yy") : null}
              </span>
            </StoryDetails>
            <StoryContent>{getFirstSentenceFromHtml(story.html)}</StoryContent>
          </StoryPreview>
        </Link>
      ))}
    </MoreStoriesContainer>
  );
};

export default MoreStories;

const getFirstSentenceFromHtml = (html: string): string => {
  // Get the first sentence from the html or the first sentence before a new line
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  return text.split(".")[0] + "...";
};
