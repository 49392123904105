import { css } from './component';

export const innerWidth = '1080px';

// uncertain as to what these classes should be called, so I'm using a variable which can easily be changed
export const smallTitleClassName = '_997247b3e14f5a6487a59adf75c14fef';
export const largeTitleClassName = '_4dcc4be157eb558ab454a342ea66d593';

export const otherBreakpoint = `@media (max-width: calc(${innerWidth} + 24px*2))`;
export const mobileBreakpoint = `@media (max-width: 768px)`;
export const desktopBreakpoint = `@media (min-width: 769px)`;

export const padding_mobile = '16px';

export const offWhiteColor = '#FFFAF0';
export const brandBlueColor = '#322387';
export const darkerBlueColor = '#110052';
export const pinkColor = '#FF0082';

export const titleFontSize_mobile = '40px';

export const smallTitleCss = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  display: block;
  text-transform: uppercase;
`;

export const largeTitleCss_desktop = css`
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
`;

export const largeTitleCss_mobile = css`
  font-weight: 700;
  font-size: ${titleFontSize_mobile};
`;

export const largeTitleCss = css`
  ${desktopBreakpoint} {
    ${largeTitleCss_desktop}
  }
  ${mobileBreakpoint} {
    ${largeTitleCss_mobile}
  }
`;

export const innerContCss = css`
  .innerCont {
    ${desktopBreakpoint} {
      max-width: ${innerWidth};
    }
    ${otherBreakpoint} {
      padding: 0 24px;
    }
    margin: 0 auto;
  }
`;

