import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { cssImg, ImageEnum } from "../../cssImg";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  largeTitleCss_desktop,
  largeTitleCss_mobile,
} from "../../styleBits";

const team = [
  {
    name: "Mariella Satow",
    link: "https://www.linkedin.com/in/mariella-satow/",
    position: "Founder",
    image: ImageEnum.aboutUs_mariella,
    bio: "Stanford University Class of ‘27. ASL Student. Fascinated by the intersection between language, technology, and accessibility. Invented SignUp to facilitate media accessibility for the Deaf community.",
  },
  {
    name: "Harriet Settler",
    link: "https://www.linkedin.com/in/harriet-seitler/",
    position: "Acting CEO",
    image: ImageEnum.aboutUs_harrietSettler,
    bio: "Emmy Award-winning CMO for The Oprah Winfrey Network, working closely with Oprah for 25 years. Current Chief Brand Officer for Course Hero.",
  },
  {
    name: "Elizabeth Corbin",
    link: "https://www.linkedin.com/in/elizabethcorbin/",
    position: "Chief Executive Team",
    image: ImageEnum.aboutUs_elizabethCorbin,
    bio: "Current Head of Digital Operations and Advanced Advertising at Sky. Deep experience in operational efficiencies.",
  },
  {
    name: "Matthew Corbin",
    link: "https://www.linkedin.com/in/matthewbcorbin/",
    position: "Chief Executive Team",
    image: ImageEnum.aboutUs_matthewCorbin,
    bio: "Former Global Head of Media and Marketer Strategy at Amazon. Current Global Growth Leader at Accenture Song.",
  },
  {
    name: "Gerry D’Angelo",
    link: "https://www.linkedin.com/in/gerrydangelo/",
    position: "Chief Advisor",
    image: ImageEnum.aboutUs_gerryDAngelo,
    bio: "Vice President, Global Media at P&G. Chair of World Federation of Advertisers’ Media Forum and Board. 30+ years of experience from all sides of the media industry.",
  },
  {
    name: "Larry Goldberg",
    link: "https://www.linkedin.com/in/lgoldberg/",
    position: "Accessibility Advisor",
    image: ImageEnum.aboutUs_larryGoldberg,
    bio: "“Godfather of Accessibility.” Former Head of Accessibility at Yahoo! and WGBH. Facilitates introductions to streaming services, builds relationships with Deaf community.",
  },
  {
    name: "Qasim Rashid",
    link: "https://www.linkedin.com/in/qasim-rashid-esq-4b228918/",
    position: "Advisor",
    image: ImageEnum.aboutUs_qasimRashid,
    bio: "Human Rights Lawyer. Over 1M followers on social media. Supporting social media strategy. Lobbying for mandatory sign language interpretation on media.",
  },
  {
    name: "Debi Pomerantz",
    link: "https://www.linkedin.com/in/debipomerantz/",
    position: "Head of Research",
    image: ImageEnum.aboutUs_debiPomerantz,
    bio: "Consumer Insights. Brand Development & Strategy. Market Research. Data and Business Analytics. Digital Media, TV, Advertising Sales. Former Warner Bros. Discovery/Discovery Communications.",
  },
  {
    name: "Angela McSwain",
    link: "https://www.linkedin.com/in/angela-mcswain-69bb52131/",
    position: "Global Interpreter Manager",
    bio: "25 years of educational American Sign Language interpreting experience in K-12 settings. Passionate about giving deaf children quality language exposure, access and inclusion in all settings.",
    image: ImageEnum.aboutUs_angelaMcSwain,
  },
  {
    name: "Stratosphere Digital",
    link: "https://stratosphere.digital",
    position: "Software Developer",
    image: ImageEnum.aboutUs_stratosphereDigital,
  },
];

@component
export class OurTeamSection extends Component {
  static styles = styled.div`
    ${desktopBreakpoint} {
      padding: 128px 0;
    }
    ${mobileBreakpoint} {
      padding: 48px 0;
    }

    ${innerContCss}

    .title {
      display: block;
      text-align: center;

      ${desktopBreakpoint} {
        ${largeTitleCss_desktop}
        margin-bottom: 64px;
      }

      ${mobileBreakpoint} {
        ${largeTitleCss_mobile}
        margin-bottom: 40px;
      }
      color: var(--h2);
    }

    .tiles {
      display: grid;
      row-gap: 40px;
      column-gap: 24px;
      ${desktopBreakpoint} {
        grid-template-columns: 50% 50%;
      }
      ${mobileBreakpoint} {
        grid-template-columns: 100%;
      }
      .tile {
        box-sizing: border-box;
        border-radius: 16px;
        display: flex;
        ${desktopBreakpoint} {
          flex-direction: row;
        }
        ${mobileBreakpoint} {
          flex-direction: column;
        }
        gap: 16px;

        ${mobileBreakpoint} {
          padding-top: 0px;
          text-align: center;
        }

        background-color: var(--surface-000);
        color: var(--subtitle);
        position: relative;
        overflow: hidden;
        padding: 0 8px;

        /* @media (max-width: 932px) {
          height: 316px;
          margin-bottom: 24px;
        } */

        .photo {
          ${desktopBreakpoint} {
            width: 160px;
            height: 160px;
          }
          ${mobileBreakpoint} {
            width: 160px;
            height: 160px;
            align-self: center;
          }
          border-radius: 16px;
          flex-shrink: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: 4px;

          a.name {
            color: var(--h2);
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -1px;
            text-decoration: none;
            &:hover {
              color: var(--subtitle);
              text-decoration-line: underline;
            }
          }

          .position {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            opacity: 0.64;
          }

          .bio {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  `;

  render() {
    return (
      <div className="innerCont" id="our-team">
        <span className="title">Our team</span>
        <div className="tiles">
          {team.map((member, index) => (
            <div
              key={index}
              className={`tile ${member.image
                .toString()
                .replace(/aboutUs\/(.+).png/, "$1")}`}
            >
              <div
                className="photo"
                style={{ backgroundImage: cssImg(member.image) }}
              />
              <div className="info">
                <a className="name" href={member.link ?? "#our-team"}>
                  {member.name}
                </a>
                <div className="position">{member.position}</div>
                <div className="bio">{member.bio}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
