import _, { entries } from 'lodash';


function makePathGen(comps=[]) {
  return new Proxy({}, {
    get({}, prop) {
      if (prop == '$') return comps;
      else if (prop == '__path') return true;
      return makePathGen(comps.concat(prop));
    }
  })
}


function getPath(path: any): string[] {
  return path.$;
}

export function pather<T>(obj?: T): T {
  return makePathGen();
}



function takePass() {
  let pass = XObject.pass;
  delete XObject.pass;
  return pass;
}

export enum XType {
  object = 'object',
  array = 'array',
}

export enum MutationType {
  set = 'set',
  unset = 'unset',
  insert = 'insert',
  remove = 'remove'
}

interface IMutationParams {
  pass?: any;
  prop?: string;
}
const IMutationParams_prop = getPath(pather<IMutation>().prop)[0];

interface IMutation extends IMutationParams {
  type: MutationType;
}

interface ISetMutationParams extends IMutationParams {
  value: any;
  prevValue: any;
}
interface ISetMutation extends IMutation, ISetMutationParams {
  type: MutationType.set;
}

interface IUnsetMutationParams extends IMutationParams {}
interface IUnsetMutation extends IMutation, IUnsetMutationParams {
  prop: string;
  type: MutationType.unset;
}

interface IInsertMutationParams extends IMutationParams {
  index: number;
  el: any;
}
interface IInsertMutation extends IMutation, IInsertMutationParams {
  type: MutationType.insert;
}

interface IRemoveMutationParams extends IMutationParams {
  index: number;
  count: number;
  els: any[];
}
interface IRemoveMutation extends IMutation, IRemoveMutationParams {
  type: MutationType.remove;
}

type Mutation = IInsertMutation | IRemoveMutation | ISetMutation | IUnsetMutation;


interface IMutationHandler<T> {
  (type: MutationType.insert, params: IInsertMutationParams): T;
  (type: MutationType.remove, params: IRemoveMutationParams): T;
  (type: MutationType.set, params: ISetMutationParams): T;
  (type: MutationType.unset, params: IUnsetMutationParams): T;
  (type: MutationType, params: IInsertMutationParams | IRemoveMutationParams | ISetMutationParams | IUnsetMutationParams ): T;

}

function constructMutation(type: MutationType, params): Mutation {
  return Object.assign({ type } as IMutation, params);
}

const createMutation: IMutationHandler<Mutation> = function (type, params) {
  return Object.assign({ type } as IMutation, params);
}

createMutation(MutationType.insert, {
  index: 1,
  el: null
})

let _id = 0;


export function _X(type?: XType): any { 
  let obj, orgObj;
  let handler;
  let transientProps;

  let disableXMap;

  let metaData;


  const propObservers: { [key: string]: { observer: Observer, map? }[] } = {};
  const propObserverObservers = {};
  const observers: Observer[] = [];
  function notifyPropObserverObservers(prop, action) {
    if (propObserverObservers[prop]) {
      for (let observer of propObserverObservers[prop]) {
        observer(propObservers[prop].length, action);        
      }
    }
  }

  function changed(action, prop, value?, prevValue?, pass?) {
  }

  const fireMutation: IMutationHandler<void> = (type: MutationType, params) => {
    const mutation = constructMutation(type, params);

    for (const observer of observers) {
      // setTimeout(() => {
        observer(mutation);
      // }, 0);
    }
    
    if (IMutationParams_prop in mutation) {
      if (propObservers[mutation.prop]) {
        for (const entry of propObservers[mutation.prop]) {
          // setTimeout(() => {
            entry.observer(entry.map ? entry.map(obj[mutation.prop]) : mutation);
          // }, 0);
        }
      }  
    }

  }


  const genHandler = {
    [XType.array]: () => {
      const observers = [];
      let map;
    
      // function callObservers(...args) {
      //   for (let observer of observers) {
      //     observer(...args);
      //   }
      // }

      // function changed(index, value?, pass?) {
      //   callObservers({ type: 'set', index, value, pass });        
      // }

      return {
        get(prop) {
          if (prop === Symbol.iterator || prop === 'length') {
            XObject.onAccess(proxy, null);
          }
    
          if (prop === 'set') {
            return function(v) {
              obj = v;
              genMetaData();
              // callObservers({type: 'set', value: v, pass: takePass()});
              fireMutation(MutationType.set, { value: v, pass: takePass() });
            }
          }
          else if (prop === 'push') {
            return function(el) {
              if (map) el = map(el);
              else {
                if (_.isPlainObject(el) || el instanceof XEntryClass) {
                  if (!el._id) {
                    el = XObject.obj(el);                
                  }
                  else {
                    el = X(el);
                  }
                }
              }
    
              const index = obj.length;
              obj.push(el);
              metaData.push(metaDataEntry());
              // callObservers({type: 'insert', index, el, pass: takePass()});
              fireMutation(MutationType.insert, { index, el, pass: takePass() });
            }
          }
          else if (prop == 'remove') {
            return function(el) {
              const index = obj.indexOf(el);
              if (index != -1) {
                (proxy as any).splice(index, 1);
                metaData.splice(index, 1);
              }
            }
          }
    
          else if (prop == 'add') {
            return function(el) {
              let index = obj.indexOf(el);
              if (index == -1) {
                (proxy as any).push(el);
                metaData.push(metaDataEntry())
              }
            }
    
          }    
          else if (prop === 'pop') {
            return () => {
              const el = obj[obj.length - 1];
              obj.pop();
              metaData.pop();
              fireMutation(MutationType.remove, { index: obj.length, count: 1, els: [ el ], pass: takePass() });  
            }
          }
          else if (prop === 'indexOf') {
            return function(...args) {
              XObject.onAccess(proxy, null);
              return obj.indexOf(...args as [any]);
            };
          }
          else if (prop === 'includes') {
            return function(...args) {
              XObject.onAccess(proxy, null);
              return obj.includes(...args as [any]);
            };
          }

          else if (prop === 'map') {
            return function(...args) {
              XObject.onAccess(proxy, null);
              return obj.map(...args as [any]);
            };
          }
          else if (prop === 'filter') {
            return function(...args) {
              XObject.onAccess(proxy, null);
              return obj.filter(...args as [any]);
            };
          }
          else if (prop === 'find') {
            return function(...args) {
              XObject.onAccess(proxy, null);
              return obj.find(...args as [any]);
            };
          }
          else if (prop === 'splice') {
            return function(start, deleteCount, ...items) {
              var ret = obj.splice(start, deleteCount, ...items)

              const newEntries = [];
              for (let i = 0; i < items.length; ++ i) {
                newEntries.push(metaDataEntry());
              }
              metaData.splice(start, deleteCount, ...newEntries);

              if (deleteCount) {
                // callObservers({type: 'remove', index: start, count: deleteCount, els: ret, pass: takePass()});
                fireMutation(MutationType.remove, { index: start, count: deleteCount, els: ret, pass: takePass() });
              }
              else {
                // callObservers({type: 'insert', index: start, el: items[0], pass: takePass()});
                fireMutation(MutationType.insert, { index: start, el: items[0], pass: takePass() });
              }
              return ret;
            }
          }
    
          else if (typeof obj[prop] === 'function') {
            return obj[prop].bind(obj);
          }
          else {
            XObject.lastAccess = { obj: proxy, prop, id: _id++ };
            return obj[prop];
          }
    
        },
        set(index, value) {
          if (index === XObject._arrayMapSymbol) {
            map = value;
          }
          else {
            obj[index] = value;
            // changed(index, value, takePass());
            fireMutation(MutationType.set, { value, prop: index, pass: takePass() });
          }
          return true;
        }
      }
    },
    [XType.object]: () => {
      let onAccessUnsetKey;

      return {
        getPrototypeOf() {
          if (orgObj && orgObj.constructor) {
            return orgObj.constructor.prototype;
          }
          return null;
        },
        get(prop: any) {

          if (!(prop in obj) && onAccessUnsetKey) {
            obj[prop] = onAccessUnsetKey();
            // let pass = takePass();
            fireMutation(MutationType.set, { prop: prop, value: obj[prop], pass: takePass() });
            // for (let observer of observers) {
            //   setTimeout(() => {
            //     observer('set', prop, obj[prop], pass);
            //   }, 0);
            // }
            
            // if (propObservers[prop]) {
            //   for (let observer of propObservers[prop]) {
            //     setTimeout(() => {
            //       observer('set', obj[prop], pass);
            //     }, 0);
            //   }
            // }
          }
    
          if (prop.indexOf && prop.indexOf('.') !== -1) {
            var p = prop.substr(0, prop.indexOf('.'));
            XObject.onAccess(proxy, p);
            return obj[p][prop.substr(prop.indexOf('.') + 1)];
          }
    
          if (!['_id', 'valueOf', 'toString', Symbol.toPrimitive].includes(prop)) {
            XObject.onAccess(proxy, prop);
          }
    
          XObject.lastAccess = { obj: proxy, prop, id: _id++ };
    
          // if (prop in obj) {
    
          // }
          // if (obj.hasOwnProperty(prop)) {
  
            const r = obj[prop];
            return r;
            if (_.isFunction(r)) {
              return r.bind(obj);
            }
            else {
              return r;
            }
          // }
          // else if (orgObj.constructor.prototype[prop]) {
          //   if (prop == 'getTime') {
          //     console.log('noooo');
          //   }
          //   return orgObj.constructor.prototype[prop];
          // }
        },
        set(prop:any, value) {
          if (prop === XObject._accessUnsetKeySymbol) {
            onAccessUnsetKey = value;
          }
          else if (prop === XObject._contentsSymbol) {
            obj = value;
          }
          else if (prop === XObject._orgSymbol) {
            orgObj = value;
          }
    
          if (prop.indexOf && prop.indexOf('.') !== -1) {
            var p = prop.substr(0, prop.indexOf('.'));
            obj[p][prop.substr(prop.indexOf('.') + 1)] = value;
            return true;
          }
    
    
          if (obj[prop] === value) return true;
    
          const prevValue = obj[prop];
    
          if (disableXMap?.[''] || disableXMap?.[prop]) obj[prop] = value;
          else obj[prop] = X(value);
    
          // changed(prop, value, prevValue, takePass())
          fireMutation(MutationType.set, { prop, value, prevValue, pass: takePass() });
          return true;
        },
        ownKeys() {
          XObject.onAccess(proxy, null);
          return Object.keys(obj);
        },
        getOwnPropertyDescriptor() {
          return {
            enumerable: true,
            configurable: true,
          };
        },
        deleteProperty(prop) {
          delete obj[prop];
          fireMutation(MutationType.unset, { prop, pass: takePass() });
          return true;
        }
      }
    }
  }

  if (type) {
    handler = genHandler[type]();
  }


  const metaDataEntry = () => {
    return { key: XObject.id() };
  }
  const genMetaData = () => {
    metaData = [];
    for (let i = 0; i < obj.length; ++ i) {
      metaData.push(metaDataEntry());
    }
  }

  const proxy = new Proxy({}, {
    getPrototypeOf({}) {
      if (transientProps) {
        if (transientProps.hasOwnProperty('')) {
          transientProps[''].get('prototype');
        }
      }

      return handler?.getPrototypeOf?.() || null;
    },
    get({}, p) {
      if (p === XObject._orgSymbol) return orgObj;
      else if (p === XObject._contentsSymbol) return obj;
      else if (p === XObject._typeSymbol) return type;
      else if (p === XObject._metaDataSymbol) return metaData
      else if (p === disableXMapSymbol) return disableXMap;
      else if (p === XObject._observeSymbol) {
        return function(prop, observer, map) {
          if (prop == 'constructor') return false;
          if (prop) {
            if (!propObservers[prop]) propObservers[prop] = [];
            propObservers[prop].push({ observer, map });
            notifyPropObserverObservers(prop, 'add');
            return true;
          }
          else {
            observers.push(observer);
            return true;
          }
        }
      }
      else if (p === XObject._removeObserverSymbol) {
        return function(prop, observer) {
          if (prop) {
            if (!propObservers[prop]) propObservers[prop] = [];
            let index = propObservers[prop].findIndex(entry => entry.observer == observer);
            if (index != -1) {
              propObservers[prop].splice(index, 1);
              notifyPropObserverObservers(prop, 'remove');
              return true;
            }
          }
          else {
            let index = observers.indexOf(observer);
            if (index != -1) {
              observers.splice(index, 1);
              return true;
            }
          }
        }
      }
      else if (p === XObject._removeAllObserversSymbol) {
        return function(prop) {
          if (prop) {
            if (propObservers[prop]) {
              propObservers[prop].splice(0, propObservers[prop].length);
            }
            notifyPropObserverObservers(prop, 'remove');
            return true;
          }
          else {
            observers.splice(0, observers.length);
            return true;
          }
        }
      }

      else if (p == XObject._observeObserversSymbol) {
        return function(prop, observer) {
          if (!propObserverObservers[prop]) {
            propObserverObservers[prop] = [];
          }
          propObserverObservers[prop].push(observer);
        }
      }
      else if (p === XObject._changedSymbol) {
        return p => {
          fireMutation(MutationType.set, { prop: p });
        }
      }
      else if (transientProps) {
        if (transientProps.hasOwnProperty(p)) {
          if (transientProps[p].trackAccess) XObject.onAccess(proxy, p);
          return transientProps[p].get();  
        }

        if (transientProps.hasOwnProperty('')) {
          return transientProps[''].get(p);
        }
      }




      return handler?.get?.(p);
    },
    set({}, p, value) {
      if (p === XObject._orgSymbol) {
        orgObj = value;
        return true;
      }
      if (p === disableXMapSymbol) {
        disableXMap = value;
        return true;
      }
      else if (p === XObject._contentsSymbol) {
        obj = value;

        if (type == XType.array) {
          genMetaData();
        }
        return true;
      }
      else if (p === XObject._typeSymbol) {
        if (type != value) {
          type = value;
          handler = genHandler[type]();

          if (type == XType.array) {
            if (type == XType.array) {
              genMetaData();
            }
          }
        }
        return true;
      }
      else if (p === XObject._transientSymbol) {
        transientProps = value;
        return true;
      }

      return handler?.set?.(p, value);
    },
    ownKeys({}) {
      return handler?.ownKeys?.();
    },
    getOwnPropertyDescriptor({}) {
      return handler?.getOwnPropertyDescriptor?.();
    },
    deleteProperty({}, p) {
      return handler?.deleteProperty?.(p);
    }
  });

  return proxy;
}

type Observer = (mutation: Mutation) => void;

export interface DeepMutation {
  type: 'set' | 'insert' | 'remove' | 'unset';
  path: string[];
  prevValue?: any;
  pass: any;
  el?: any;
  value?: any;
  index?: any;
  key?: any;
}
type DeepObserver = (mutation: DeepMutation) => void;

type XObjectType = {
  lastAccess: any;
  _contentsSymbol: any;
  _orgSymbol: any;
  _typeSymbol: any;
  _observeSymbol: any;
  _observeObserversSymbol; any;
  _removeObserverSymbol: any;
  _removeAllObserversSymbol: any;
  _arrayMapSymbol: any;
  _accessUnsetKeySymbol: any;
  _transientSymbol: any;
  _changedSymbol: any;
  _metaDataSymbol: any;
  get: any;
  push;
  observe: {
    (o: any, prop: string, observer: Observer, map?): boolean;
    (o: any, observer: DeepObserver, cb?: Function): boolean;
    (o: any, observer: DeepObserver, n: null | undefined, cb?: Function): boolean;
  };
  removeObserver: (o: any, prop: any, observer: any) => boolean;
  removeAllObservers: (o: any, prop: any) => boolean;
  observeObservers: any;
  isArray: any;
  isObject: any;
  onAccess: any;
  captureAccesses(func, observer: (obj, prop) => void);
  obj: <T=any>(obj?: T) => T & { _id: any };
  id: any;
  pass: any;
  _onAccess: any;

  changed: (proxy: any, prop?: any) => void;

  disableXMap(a, b);

} & ((__, ___) => any)


function _XObject() {
  return _X(XType.object);
}
export const XObject = function(obj = {}, orgObj=null) {
  const proxy = _XObject();
  proxy[XObject._contentsSymbol] = obj;
  proxy[XObject._orgSymbol] = orgObj;
  return proxy;
} as any as XObjectType;



export const ObjectID = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

XObject.id = function() {
  return ObjectID();
}


XObject.disableXMap = function(obj, prop) {
  if (!obj[disableXMapSymbol]) obj[disableXMapSymbol] = {};
  obj[disableXMapSymbol][prop] = true;
}

const disableXMapSymbol = Symbol('disableXMap');

XObject._contentsSymbol = Symbol('contents');
XObject._orgSymbol = Symbol('org');
XObject._typeSymbol = Symbol('type');
XObject._observeSymbol = Symbol('observe');
XObject._changedSymbol = Symbol('changed');
XObject._observeObserversSymbol = Symbol('observeObservers');
XObject._removeObserverSymbol = Symbol('removeObserver');
XObject._removeAllObserversSymbol = Symbol('removeAllObservers');
XObject._arrayMapSymbol = Symbol('arrayMap');
XObject._accessUnsetKeySymbol = Symbol('accessUnsetKey');
XObject._transientSymbol = Symbol('asdf');
XObject._metaDataSymbol = Symbol('_metaDataSymbol');

XObject.get = function(o, prop, defaultValue) {
  if (o[prop] === undefined) {
    return o[prop] = X(defaultValue);
  }
  else {
    return o[prop];
  }
}

XObject.push = function (o, prop, value) {
  if (!o[prop]) o[prop] = X([ value ]);
  else o[prop].push(value);
}

function _observeChanges(obj, path = [], observer: DeepObserver, cb) {
  if (XObject.isObject(obj)) {
    let handler: Observer;
    if (XObject.observe(obj, null, handler = mutation => {
      if (mutation.type == MutationType.set) {
        const completePath = path.concat(mutation.prop);
        _observeChanges(mutation.value, completePath, observer, cb);
        observer({ type: 'set', path: completePath, value: mutation.value, prevValue: mutation.prevValue, pass: mutation.pass, el: obj });  
      }
      else if (mutation.type == MutationType.unset) {
        const completePath = path.concat(mutation.prop);
        _observeChanges(null, completePath, observer, cb);
        observer({ type: 'unset', path: completePath, pass: mutation.pass, el: obj });  
      }
      else {
        console.error('unhandled mutation', mutation);
        // throw new Error('unhandled mutation');
      }
    })) {
      if (cb) {
        cb(obj, null, handler);
      }  
    }

    for (const key of Object.keys(obj)) {
      _observeChanges(obj[key], path.concat(key), observer, cb);
    }
  }
  else if (XObject.isArray(obj)) {
    let handler: Observer;
    if (XObject.observe(obj, null, handler = mutation => {
      // console.log(mutation)
      if (mutation.type === MutationType.insert) {
        if (mutation.el?._id) {
          _observeChanges(mutation.el, path.concat('&' + mutation.el._id), observer, cb);
          observer({ type: 'insert', path: path.concat(mutation.index), el: mutation.el, pass: mutation.pass });
        }
        else {
          _observeChanges(mutation.el, path.concat(mutation.index), observer, cb);
          observer({ type: 'insert', path: path.concat(mutation.index), el: mutation.el, pass: mutation.pass });
        }
      }
      else if (mutation.type === MutationType.remove) {
        if (mutation.els[0]?._id) {
          observer({ type: 'remove', path: path, key: mutation.els[0]._id, pass: mutation.pass });  
        }
        else {
          observer({ type: 'remove', path: path, index: mutation.index, pass: mutation.pass });  
        }
      }
      else if (mutation.type === MutationType.set) {
        const comp = obj[mutation.prop]._id ? '&' + obj[mutation.prop]._id : mutation.prop;
        _observeChanges(mutation.value, path.concat(comp), observer, cb);
        observer({ type: 'set', path: path.concat(comp), value: mutation.value, pass: mutation.pass, el: obj });
      }
    })) {
      if (cb) {
        cb(obj, null, handler);
      }  
    }
    for (let i = 0; i < obj.length; ++ i) {
      if (obj[i] !== null && obj[i] !== undefined) {
        const comp = obj[i]._id ? '&' + obj[i]._id : i;
        _observeChanges(obj[i], path.concat(comp), observer, cb);  
      }
    }
  }
}


XObject.observe = function(o, prop, observer, cb?) {
  if (!observer) {
    return _observeChanges(o, [], prop, cb);
  }
  else {
    return o[XObject._observeSymbol](prop, observer, cb);
  }
}

XObject.removeObserver = function(o, prop, observer) {
  return o[XObject._removeObserverSymbol](prop, observer);
}

XObject.removeAllObservers = function(o, prop) {
  return o[XObject._removeAllObserversSymbol](prop);
}

XObject.observeObservers = function(o, prop, observer) {
  o[XObject._observeObserversSymbol](prop, observer);
}

XObject.isArray = function(obj) {
  return obj && obj[XObject._typeSymbol] === 'array';
}

XObject.isObject = function(obj) {
  return obj && obj[XObject._typeSymbol] === 'object';
}

XObject.onAccess = function(...args) {
  if (this._onAccess) {
    this._onAccess(...args);
  }
}

XObject.captureAccesses = function(func, onAccess) {
  XObject._onAccess = onAccess;
  var result = func();
  delete XObject._onAccess;
  return result;
}

XObject.obj = function(obj:any ={}) {
  if (obj._id) {
    return XObject(XMap(obj), obj);
  }
  else {
    return XObject(Object.assign({}, XMap(obj), { _id: XObject.id() }), obj);
  }
}

function resolvePath(path) {
  if (path.__path) path = path.$;

  if (_.isArray(path)) {
    return path[0];
  }
  else {
    return path;
  }
}

XObject.changed = (proxy, path) => {
  proxy[XObject._changedSymbol](resolvePath(path));
}

function _XArray() {
  return _X(XType.array);
}

export function XArray(list=[]) {
  const proxy = _XArray();
  proxy[XObject._contentsSymbol] = list;
  return proxy;
};

// declare const test: <T>(klass: (new () => T)) => T;

export function XInit<T>(klass: (new () => T)): T {
  let t = new klass();
  return XMap(t, true);

}

export type XID = string | number;

export type XEntry = {
  _id?: XID;
}

export class XEntryClass {
  _id?: XID;
}

export function XMap<T>(obj: T, force=false, map?: Map<any, any>, _proxy?): T {
  let anyObj = obj as any;
  if (obj === undefined || obj === null || obj[XObject._typeSymbol]) return obj;

  if (!map) {
    map = new Map();
  }
  else {
    let o = map.get(obj);
    if (o !== undefined) return o;
  }

  if (_.isArray(obj)) {
    const proxy = _proxy || _XArray();
    if (_proxy) {
      _proxy[XObject._typeSymbol] = XType.array;
    }
    map.set(obj, proxy);

    proxy[XObject._contentsSymbol] = anyObj.map((value) => {
      return XMap(value, undefined, map);
    });

    return proxy;
  }
  else if ((obj instanceof Object && !(obj instanceof Date)) || force) {
    const proxy = _proxy || _XObject();
    if (_proxy) {
      _proxy[XObject._typeSymbol] = XType.object;
    }
    map.set(obj, proxy);

    proxy[XObject._contentsSymbol] = _.mapValues(obj as any, value => {
      return XMap(value, undefined, map);
    });
    proxy[XObject._orgSymbol] = obj;

    return proxy;
  }
  else {
    return obj;
  }
}


export function XStrip<T>(obj: T, recursive=true, map?: Map<any, any>): T {
  if (obj === null) return null;
  else if (obj === undefined) return undefined;

  if (!map) {
    map = new Map();
  }
  else {
    let o = map.get(obj);
    if (o !== undefined) return o;
  }


  let constructor, orgObj = obj;
  if (obj[XObject._typeSymbol]) {
    let org = obj[XObject._orgSymbol];
    if (org && org.constructor != Object && org.constructor != Array) constructor = org.constructor;
    obj = obj[XObject._contentsSymbol];
  }

  if (_.isArray(obj)) {
    let o = [];
    map.set(orgObj, o);
    
    o.splice(0, 0, ...(obj as any).map(v => {
      if (recursive) {
        return XStrip(v, undefined, map);
      }
      else {
        return v;
      }
    }));

    return o as any;
  }
  else if (_.isPlainObject(obj)) {
    let r;
    if (constructor?.prototype) {
      r = Object.create(constructor.prototype);
    }
    else {
      r = {};
    }
    map.set(orgObj, r);


    let values = _.mapValues<any>(obj, (v) => {
      if (recursive) {
        return XStrip(v, undefined, map);
      }
      else {
        return v;
      }
    });

    for (let key in values) r[key] = values[key];

    return r;
  }
  else {
    return obj;
  }
}

export const X = XMap;
export const x = XStrip;

export class XValue {
  obj: any;
  constructor(value) {
    this.obj = X({ value });
  }
  get() {
    return this.obj.value;
  }
  set(value) {
    this.obj.value = value;
  }
  observe(observer) {
    XObject.observe(this.obj, 'value', observer);
  }
}

function _isArray(value) {
  return _.isArray(value) || XObject.isArray(value);
}

function _isObject(value) {
  return _.isPlainObject(value) || XObject.isObject(value);
}

export function XTouch(obj, path=[]) {
  if (_isArray(obj)) {
    if (XObject.isArray(obj)) {
      XObject.onAccess(obj, null)
    }

    // eslint-disable-next-line
    obj.length;

    let i = 0;
    for (const el of obj) {
      XTouch(el, path.concat(i));
      ++i;
    }
  }
  else if (_isObject(obj)) {
    if (XObject.isObject(obj)) {
      XObject.onAccess(obj, null)
    }
    for (const prop in obj) {
      // eslint-disable-next-line
      obj[prop];
      XTouch(obj[prop], path.concat(prop));
    }
  }
}
