import React, { useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, ButtonTheme } from "../Button";
import { ImageEnum, imgBlob } from "../../cssImg";
import { API_URL } from "../../api";

export default function CheckoutForm({ payload, setPayload }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }

      let response;
      if (payload.isRecurring) {
        response = await fetch(`${API_URL}/create-customer-intent`, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      } else {
        response = await fetch(`${API_URL}/create-payment-intent`, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      }

      const json = await response.json();

      // Store the payment intent id in session storage to be used in the completion page
      sessionStorage.setItem(json.clientSecret, payload.isRecurring);

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: json.clientSecret,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/completion`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="form-header">Contact Information</div>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(event) => {
          setPayload({ ...payload, email: event.value?.email });
        }}
      />
      <AddressElement
        options={{ mode: "billing" }}
        onChange={(event) => {
          setPayload({
            ...payload,
            name: event.value?.name,
            country: event.value?.address?.country,
          });
        }}
      />
      <div className="form-header">Billing Information</div>
      <PaymentElement id="payment-element" />
      <Button
        className="w100 submit-button"
        theme={ButtonTheme.primary}
        disabled={isLoading || !stripe || !elements || !payload?.amount}
        text={
          <>
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : payload?.amount ? (
                `Donate $${payload.amount}`
              ) : (
                `Submit Donation`
              )}
            </span>
            <img
              src={imgBlob(ImageEnum.giving)}
              alt="Donate"
              width={24}
              height={24}
            />
          </>
        }
      />

      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
