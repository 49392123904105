import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { cssImg, ImageEnum } from "../../cssImg";
import { cmsFields } from "../../cmsFields";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  padding_mobile,
  titleFontSize_mobile,
  smallTitleCss,
  largeTitleCss_desktop,
  smallTitleClassName,
  largeTitleClassName,
} from "../../styleBits";
import { cmsPicture } from "../../cmsData";
import { cms } from "../../cms";
import { Video } from "../Video";
import { ButtonTheme } from "../Button";
import { RadioButton } from "../RadioButton";

@component
export class Section1 extends Component<{ className?: string }> {
  static styles = styled.div`
    position: relative;
    ${innerContCss}

    ${desktopBreakpoint} {
      margin-top: 230px;
      padding-bottom: 128px;
      padding-top: 100px;
    }

    ${mobileBreakpoint} {
      padding: 0 ${padding_mobile} 40px;
    }

    .videos {
      ${desktopBreakpoint} {
        position: absolute;
        top: -310px;
        left: 0;
        right: 0;
        overflow: hidden;
        height: 430px;
      }
    }

    .video3,
    .video2,
    .video1 {
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      border-radius: 20px;
      box-shadow: 0px 8px 32px 2px rgba(17, 0, 82, 0.4);

      ${desktopBreakpoint} {
        top: 0;
        left: 50%;
      }
    }

    .video3 {
      top: 0;
      width: 428px;
      height: 240px;
      background-image: ${cmsPicture(cmsFields.Header_Photo3)};
      position: absolute;
      margin-left: 100px;
    }

    .video2 {
      width: 640px;
      height: 360px;
      background-image: ${cmsPicture(cmsFields.Header_Photo2)};
      z-index: 1;
      top: 30px;

      ${desktopBreakpoint} {
        margin-left: -320px;
      }
      ${mobileBreakpoint} {
        margin-top: -37px;
      }
    }

    .video1 {
      width: 428px;
      height: 240px;
      background-image: ${cmsPicture(cmsFields.Header_Photo1)};
      margin-left: calc(-428px - 100px);
      z-index: 0;
      top: 0;
    }

    ${mobileBreakpoint} {
      .video3,
      .video1 {
        display: none;
      }

      .video2 {
        width: 100%;
        height: auto;
        aspect-ratio: 322/181;
        position: static;
      }
    }

    .aboutUs {
      ${desktopBreakpoint} {
        margin-top: 40px;
        gap: 64px;
      }

      ${mobileBreakpoint} {
        margin-top: 40px;
        gap: 24px;
        flex-direction: column;
      }
      display: flex;
      width: 100%;
      .aboutUsText {
        flex-grow: 1;
        align-self: center;
        p {
          margin-bottom: 0;
        }
      }

      .video-container {
        ${desktopBreakpoint} {
          width: 536px;
          top: 0;
          right: 0;
          bottom: 0;
        }
        ${mobileBreakpoint} {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 536/280;
        }

        .languages {
          ${mobileBreakpoint} {
            > span {
              flex-basis: 100%;
            }
          }
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          border: none;
          padding: 0;
          margin: 16px 0 0;
          align-items: center;
        }
      }
      .video {
        ${desktopBreakpoint} {
          width: 536px;
          height: 280px;
        }
        ${mobileBreakpoint} {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 536/280;
          margin-top: 24px;
        }

        &[data-id="bsl"] {
          background-image: ${cssImg(ImageEnum.aboutUsThumbBSL)};
          background-image: image-set(
            ${cssImg(ImageEnum.aboutUsThumbBSL)} 1x,
            ${cssImg(ImageEnum.aboutUsThumbBSL2x)} 2x
          );
        }

        &[data-id="asl"] {
          background-image: ${cssImg(ImageEnum.aboutUsThumbASL)};
          background-image: image-set(
            ${cssImg(ImageEnum.aboutUsThumbASL)} 1x,
            ${cssImg(ImageEnum.aboutUsThumbASL2x)} 2x
          );
        }

        &[data-id="isl"] {
          background-image: ${cssImg(ImageEnum.aboutUsThumbISL)};
          background-image: image-set(
            ${cssImg(ImageEnum.aboutUsThumbISL)} 1x,
            ${cssImg(ImageEnum.aboutUsThumbISL2x)} 2x
          );
        }

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0px 8px 32px 2px rgba(17, 0, 82, 0.4);
        border-radius: 12px;
        cursor: pointer;

        &:focus {
          outline: 1px solid var(--outline-border);
        }

        iframe {
          width: 100%;
          height: 100%;
          border: none;
          box-sizing: border-box;
        }
      }

      .${smallTitleClassName} {
        ${smallTitleCss}
      }

      .${largeTitleClassName} {
        ${largeTitleCss_desktop}
        display: block;

        @media (max-width: 340px) {
          font-size: ${titleFontSize_mobile};
        }
      }

      p {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
    }
  `;

  state = {
    showVideo: false,
    id: "asl",
  };

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{ showVideo: boolean }>
  ): void {
    if (prevState.showVideo && !this.state.showVideo) {
      const iframeElement = document.querySelector(".video.iframe");
      if (iframeElement instanceof HTMLElement) {
        iframeElement.focus();
      }
    }
  }

  render() {
    const languages = ["asl", "bsl", "isl"];
    return (
      <div className="innerCont">
        {this.state.showVideo && (
          <Video
            onClickClose={() => {
              this.setState({ showVideo: false });
            }}
            id={this.state.id}
          />
        )}
        <div
          className="videos"
          aria-label="Screenshots of the movies Up, The Incredible's and Moana with an interpreter translating for sign language"
        >
          <span
            className="video1"
            role="img"
            aria-label="A screenshot of the movie Up with an interpreter translating for sign language"
          />
          <span
            className="video2"
            role="img"
            aria-label="A screenshot of the movie The Incredible's with an interpreter translating for sign language"
          />
          <span
            className="video3"
            role="img"
            aria-label="A screenshot of the movie Moana with an interpreter translating for sign language"
          />
        </div>
        <div className="aboutUs" id="about">
          <div className="aboutUsText">
            <h2 className={largeTitleClassName}>
              {cms(cmsFields.AboutUs_Title)}
            </h2>
            <p>{cms(cmsFields.AboutUs_Text)}</p>
          </div>

          <div className="video-container">
            <div
              className="video iframe"
              data-id={this.state.id}
              role="button"
              tabIndex={0}
              aria-label="Opens a SignUp Captions demonstration video in a dialog"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ showVideo: true });
                }
              }}
              onClick={() => {
                this.setState({ showVideo: true });
              }}
            />
            <div className="languages">
              <span>Video interpretation language:</span>

              {languages.map((lang) => {
                return (
                  <RadioButton
                    key={lang}
                    theme={ButtonTheme.secondary2}
                    className="slim"
                    checked={this.state.id === lang}
                    onClick={() => {
                      this.setState({ id: lang });
                    }}
                  >
                    <label htmlFor={lang}>{lang.toLocaleUpperCase()}</label>
                    <input
                      type="radio"
                      id={lang}
                      name="language"
                      value={lang}
                      checked={this.state.id === lang}
                      onChange={() => {
                        this.setState({ id: lang });
                      }}
                    />
                  </RadioButton>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
