import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 24px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: min-content;
  padding: 0 8px;
  text-transform: uppercase;
`;

const Format = ({ format }) => {
  return <Container>{format}</Container>;
};

export default Format;
