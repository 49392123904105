import { Modal } from "./Modal";
import { ImageEnum, imgBlob } from "../cssImg";
import styled from "styled-components";
import { desktopBreakpoint, mobileBreakpoint } from "../styleBits";
import { Button, ButtonTheme } from "./Button";
import { Field } from "./Field";
import React, { useState } from "react";
import { sendEmail } from "../sendEmail";
import _ from "lodash";
import { DropdownSelect } from "./DropdownSelect";
import { languageOptions } from "../data/languages";

const ContactUsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${desktopBreakpoint} {
    width: 600px;
  }
  .dual-input-line {
    display: flex;
    gap: 16px;
    ${desktopBreakpoint} {
      > * {
        flex-grow: 1;
      }
    }
    ${mobileBreakpoint} {
      flex-direction: column;
    }
  }
  .submit-line {
    display: flex;
    place-content: end;
  }
  .letter {
  }
`;

export function ContactUsModal(props: { onClose: { (): void } }) {
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [language, setLanguage] = useState<null | string>(null);
  const onSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    const target = e.target as any;
    if (!target.firstName.value) {
      newErrors["firstName"] = true;
    }
    if (!target.lastName.value) {
      newErrors["lastName"] = true;
    }
    if (!target.email.value) {
      newErrors["email"] = true;
    }
    if (!target.message.value) {
      newErrors["message"] = true;
    }
    if (target.language.value === "Other" && !target.other.value) {
      newErrors["otherLanguage"] = true;
    }
    setErrors(newErrors);
    if (!_.isEmpty(newErrors)) {
      // go to the first error and focus on it
      const firstError = Object.keys(newErrors)[0];
      target?.[firstError]?.focus();
      return;
    }

    sendEmail({
      firstName: target.firstName.value,
      lastName: target.lastName.value,
      emailAddress: target.email.value,
      language:
        (language === "Other" ? target.otherLanguage.value : language) ?? "-",
      message: target.message.value,
    });

    alert("Thank you for your message! We will get back to you soon.");
    target.reset();
    gtag("event", "Message sent");
  };
  return (
    <Modal title="Contact Us" onClose={props.onClose}>
      <ContactUsForm onSubmit={onSubmit}>
        <p>We're looking forward to hearing from you!</p>
        <div className="dual-input-line">
          <Field
            label="First name"
            postfix="(required)"
            labelFor="contactUs-lastName"
            hintId="contactUs-lastName-hint"
            error={errors.firstName}
            errorMessage="Please enter at least 1 character."
          >
            <input
              type="text"
              name="firstName"
              id="contactUs-firstName"
              required
              className={errors["firstName"] && "error"}
              aria-invalid={errors["firstName"]}
              aria-describedby="contactUs-firstName-hint"
            />
          </Field>
          <Field
            label="Last name"
            postfix="(required)"
            labelFor="contactUs-lastName"
            hintId="contactUs-lastName-hint"
            error={errors.lastName}
            errorMessage="Please enter at least 1 character."
          >
            <input
              type="text"
              name="lastName"
              id="contactUs-lastName"
              required
              className={errors["lastName"] && "error"}
              aria-invalid={errors["lastName"]}
              aria-describedby="contactUs-lastName-hint"
            />
          </Field>
        </div>
        <Field
          label="Email"
          postfix="(required)"
          labelFor="contactUs-email"
          hintId="contactUs-email-hint"
          errorMessage="Please enter a valid email address."
        >
          <input
            type="text"
            name="email"
            id="contactUs-email"
            required
            className={errors["email"] && "error"}
            aria-invalid={errors["email"]}
            aria-describedby="contactUs-email-hint"
          />
        </Field>

        <Field label="Language" labelFor="contactUs-language">
          <DropdownSelect
            id="language"
            options={languageOptions}
            value={language}
            onChange={(value) => {
              setLanguage(value);
            }}
          />
        </Field>
        {language === "Other" ? (
          <Field
            label="Other Language"
            postfix="(required)"
            labelFor="contactUs-other"
            error={errors["otherLanguage"]}
            errorMessage="Please enter a language."
            hintId="contactUs-otherLanguage-hint"
          >
            <input
              type="text"
              name="otherLanguage"
              required
              aria-describedby="contactUs-otherLanguage-hint"
              aria-invalid={errors["otherLanguage"]}
              className={errors["otherLanguage"] && "error"}
            />
          </Field>
        ) : null}
        <Field
          label="Message"
          postfix="(required)"
          labelFor="contactUs-message"
          hintId="contactUs-message-hint"
        >
          <textarea
            name="message"
            id="contactUs-message"
            required
            className={errors["message"] && "error"}
            aria-invalid={errors["message"]}
            aria-describedby="contactUs-message-hint"
          />
        </Field>
        <div className="submit-line">
          <Button
            text={
              <>
                Submit
                <img
                  className="letter"
                  src={imgBlob(ImageEnum.letter)}
                  alt="Letter"
                />
              </>
            }
            theme={ButtonTheme.primary}
          />
        </div>
      </ContactUsForm>
    </Modal>
  );
}
