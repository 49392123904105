import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../component";
import { Button, ButtonTheme } from "./Button";
import { mobileBreakpoint } from "../styleBits";
import ReactDOM from "react-dom";

const videoMap = {
  asl: `/asl.mp4`,
  isl: `/isl.mp4`,
  bsl: `/bsl.mp4`,
};

const inertIds = [
  "#signly-app",
  "#signly-button",
  "#signly-border",
  "#signly-playing-border",
  "#skip",
  "#root",
  ".signly-drop-zone.left",
  ".signly-drop-zone.right",
];

@component
export class Video extends Component<{ onClickClose; id: string }> {
  static styles = styled.dialog`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    gap: 8px;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;

    video {
      border: none;
      border-radius: 8px;
      max-width: calc(100% - 100px);
      height: auto;
      max-height: calc(100% - 240px);
      box-shadow: 0px 8px 32px 2px rgba(17, 0, 82, 0.4);

      ${mobileBreakpoint} {
        max-width: calc(100% - 8px);
      }
    }
  `;

  onPress(e) {
    if (e.key === "Escape") {
      this.props.onClickClose();
    }

    console.log(document.activeElement.id);
    // if shift tab
    if (e.shiftKey && e.key === "Tab") {
      console.log("shift tab");
      if (document.activeElement.id === "video") {
        e.preventDefault();
        document.getElementById("modalClose").focus();
        return;
      }
    }

    if (e.key === "Tab") {
      if (document.activeElement.id === "modalClose") {
        e.preventDefault();
        document.getElementById("video").focus();
      }
    }
  }

  onClickClose(e) {
    if (e.target === e.currentTarget) {
      this.props.onClickClose();
    }
  }

  onScrollClose(e) {
    this.props.onClickClose();
  }

  componentDidMount(): void {
    inertIds.forEach((id) => {
      const el = document.querySelector(id);
      if (el) {
        el.setAttribute("inert", "true");
        el.setAttribute("aria-hidden", "true");
      }
    });

    window.addEventListener("keydown", this.onPress.bind(this));
    document.addEventListener("click", this.onClickClose.bind(this));
    document.addEventListener("scroll", this.onScrollClose.bind(this));
  }

  componentWillUnmount(): void {
    inertIds.forEach((id) => {
      const el = document.querySelector(id);
      if (el) {
        el.removeAttribute("inert");
        el.removeAttribute("aria-hidden");
      }
    });

    window.removeEventListener("keydown", this.onPress.bind(this));
    document.removeEventListener("click", this.onClickClose.bind(this));
    document.removeEventListener("scroll", this.onScrollClose.bind(this));
  }

  render(Container?) {
    return ReactDOM.createPortal(
      <Container
        aria-modal="true"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            this.props.onClickClose();
          }
        }}
      >
        <h2 className="sr-only" tabIndex={-1}>
          How to download extension
        </h2>
        <video
          autoFocus
          id="video"
          onLoadStart={(e: any) => {
            e.target.focus();
          }}
          src={videoMap[this.props.id]}
          autoPlay
          controls
          title="YouTube video player"
        />
        <Button
          id="modalClose"
          onClick={() => {
            this.props.onClickClose();
          }}
          theme={ButtonTheme.primary}
          text="Close"
        />
      </Container>,
      document.querySelector("body")
    );
  }
}
