import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { Section1 } from "../components/sections/Section1";
import { SupportedMoviesSection } from "../components/sections/SupportedMoviesSection";
import { FrequentlyAskedQuestionsSection } from "../components/sections/FrequentlyAskedQuestionsSection";
import { PressSection } from "../components/sections/PressSection";
import { WorkWithUsSection } from "../components/sections/WorkWithUsSection";
import { NewsletterSection } from "../components/sections/Newsletter";
import { TestimonialsSection } from "../components/sections/TestimonialsSection";
import { CTA } from "../components/sections/CTA";
import { OurTeamSection } from "../components/sections/OurTeamSection";
import { RequestAMovieSection } from "../components/sections/RequestAMovieSection";
import Timeline from "./Timeline";

function Home() {
  const theme: any = useContext(ThemeContext);
  const { loaded, data } = theme;
  if (!loaded) return;
  return (
    <>
      <CTA />
      <Section1 className="alternative" />
      <TestimonialsSection />
      <SupportedMoviesSection data={data} className="alternative" />
      <FrequentlyAskedQuestionsSection />
      <PressSection className="alternative" />
      <OurTeamSection />
      <RequestAMovieSection className="alternative" />
      <Timeline />
      <WorkWithUsSection />
      <NewsletterSection />
    </>
  );
}

export default Home;
