import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { XObject } from './XObject';
import _styled from 'styled-components';
import _ from 'lodash';
import md5 from 'md5';

window['ReactDOM'] = ReactDOM;


export class StillLoading extends Error {
  constructor(public arg) {
    super()
  }
}

export function img(name) {
  // if (!_.isString(name)) {
  //   console.log(name);
  //   throw new Error('name not string');
  // }
  // return `url(${require(`@/images/${name}`)})`;
  return null;
}

export function w<T, TT>(a?, b?: T, c?: TT): React.ComponentType<any> & TT {
  return Object.assign(b, c) as any;
}


export function css(strings, ...interpolations) {
  for (let i of interpolations) {
    if (_.isFunction(i) && !(i as any).styledComponentId || _.isArray(i)) {
      return [...arguments];
    }
  }

  let str = '';
  for (let i = 0; i < strings.length; ++i) {
    str += strings[i] + (interpolations[i] === undefined || interpolations[i] === false ? '' : interpolations[i]);
  }
  return str;
}

css.switch = (prop, cases, defaultValue) => {
  return props => { return cases[_.get(props, prop) || defaultValue]; }
}

css.img = img;
css.svg = name => css.img(`${name}.svg`);

css.breakpoint = new Proxy({}, {
  get(__, breakpoint) {
    return st => (props => props.theme.breakpoint == breakpoint && (_.isFunction(st) ? st(props) : st))
  }
}) as {
  desktop: Function;
  mobile: Function;
};


export const styled = new Proxy({}, {
  get(__, tag) {
    return new Proxy(() => { }, {
      apply(__, thisArg, args) {
        return _styled[tag](...args);
      },
      get(__, param) {
        return _styled[tag][param];
      }
    })
  }
}) as any;


function aliasStyledComponent(comp, styledComponent) {
  comp.toString = () => styledComponent.toString();
  comp[Symbol.toPrimitive] = () => styledComponent.toString();
  comp.valueOf = () => styledComponent.toString();
  comp.styledComponentId = styledComponent.styledComponentId;

  return comp;
}

function genStyled(tag, styles) {
  return _styled[tag](...(styles || ['']));
}

function makeStyled(constructor, StyledComp) {
  aliasStyledComponent(constructor, StyledComp);
  let { render } = constructor.prototype;

  if (constructor.debug) {
    console.log(render.length);
  }
  if (!render) {
    constructor.prototype.render = function () {
      return <StyledComp {...this.props} />;
    }
  }
  else if (render.length == 1) {
    constructor.prototype.render = function () {
      return render.call(this, StyledComp);
    }
  }
  else {
    constructor.prototype.render = function () {
      return <StyledComp {...this.props}>{render.call(this)}</StyledComp>;
    }
  }
}


let nextReactiveId = 0;

export const reactiveCompMap = {};

window['g_reactiveCompMap'] = reactiveCompMap;

function makeReactive(constructor) {
  const { render, componentWillUnmount, componentDidMount, componentDidUpdate } = constructor.prototype;

  constructor.prototype.trackObserver = function (obj, prop, observer, tag) {
    if (!this.observing) this.observing = [];
    if (!this.observing.find(o => o.obj === obj && o.prop === prop)) {
      this.observing.push({ obj, prop, observer, tag });
      return true;
    }
  }

  constructor.prototype.componentDidMount = function () {
    const id = nextReactiveId++;
    const domNode = ReactDOM.findDOMNode(this) as Element;

    reactiveCompMap[id] = this;
    this.__reactiveId = id;

    if (domNode?.setAttribute) {
      domNode.setAttribute('data-reactive', id.toString());
    }
    else {
      // console.log(this, this.__name, domNode);
    }

    if (componentDidMount) componentDidMount.call(this);
  }

  constructor.prototype.componentDidUpdate = function () {
    const domNode = ReactDOM.findDOMNode(this) as Element;
    if (domNode?.getAttribute && !domNode.getAttribute('data-reactive')) {
      domNode.setAttribute('data-reactive', this.__reactiveId);
    }

    if (componentDidUpdate) componentDidUpdate.apply(this, arguments);
  }

  constructor.prototype.render = function () {
    let timerId;
    if (render) {
      return XObject.captureAccesses(() => {
        if (this.catchErrors || this.props.catchErrors) {
          try {
            const r = render.call(this);
            if (r === undefined) return null;
            return r;
          }
          catch (e) {
            if (e instanceof StillLoading) {
              // console.log(e);
              return <span>Loading...</span>;
            }
            console.log('ERRROR', e);
            return <span>error</span>;
          }
        }
        else {
          try {
            const r = render.call(this);
            if (r === undefined) return null;
            return r;
          }
          catch (e) {
            if (e instanceof StillLoading) {
              // console.log(e);
              return <span>Loading...</span>;
            }
            else {
              console.log('ERRROR', e);
              return 'error';
            }
          }
        }
      }, (obj, prop) => {
        const observer = (...args) => {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            try {
              // console.log(this, ReactDOM.findDOMNode(this), args);
              this.forceUpdate();
            }
            catch (e) {
              console.log('failed to update', this);
            }
          }, 100);
        }
        if (this.trackObserver(obj, prop, observer, 1)) {
          XObject.observe(obj, prop, observer);
        }
      });
    }
    else {
      return null;
    }
  }

  constructor.prototype.componentWillUnmount = function () {
    delete reactiveCompMap[this.__reactiveId];
    if (this.observing) {
      for (let { obj, prop, observer, tag } of this.observing) {
        XObject.removeObserver(obj, prop, observer)
        // if (!XObject.removeObserver(obj, prop, observer)) {
        //   console.log('failed to remove', x(obj), prop, observer, tag);
        // }
      }
      delete this.observing;
    }

    if (componentWillUnmount) componentWillUnmount.call(this);
  }
}


export function generateClassName(name = '') {
  return name + '_' + md5(Math.random().toString());
}

function generateClassNames(c) {
  const generated = {};
  for (const name in c) {
    if (c[name] == '') generated[name] = generateClassName(name);
    else if (_.isPlainObject(c[name])) {
      generated[name] = Object.assign(generateClassName(name), generateClassNames(c[name]))
    }
    else generated[name] = c[name];
  }
  return generated;
}


export const component = new Proxy(() => { }, {
  apply(target, thisArg, args) {
    if (args[0] instanceof Function) {
      const [constructor] = args;
      if (constructor.c) {
        constructor.c = generateClassNames(constructor.c);
      }
      if (constructor.styles) {
        makeStyled(constructor, _.isFunction(constructor.styles) ? constructor.styles(constructor.classes) : constructor.styles);
      }
      makeReactive(constructor);
      return constructor;
    }
    else {
      const [styles] = args;
      return constructor => {
        if (styles.styledComponentId) {
          makeStyled(constructor, styles);
        }
        else {
          makeStyled(constructor, genStyled('div', styles));
        }

        makeReactive(constructor);
      }
    }
  },
  get(target, tag) {
    return styles => {
      return constructor => {
        makeStyled(constructor, genStyled(tag, styles));
        makeReactive(constructor);
      }
    }
  }
}) as (<T>(constructor: T) => T);

export function scoped<T>(gen: () => T): T {
  return gen();
}


export const reactDisplay = Symbol('reactDisplay');


const portal = ReactDOM.createPortal(null, document.body);

export function wrapReact(React) {
  const createElement = React.createElement
  React.createElement = function (type, props, ...children) {
    // console.log(type, props, children);
    children = children.map(child => {
      if (child) {
        if (child[reactDisplay]) {
          child = child[reactDisplay];
          if (_.isFunction(child)) child = child();
        }
        else if (child.$$typeof != portal['$$typeof'] && !React.isValidElement(child) && !_.isFunction(child) && !_.isString(child) && !_.isArray(child) && !_.isNumber(child)) {
          console.log(child);
          child = '--error--';//createElement.call(React, React.Fragment, null);
        }
      }
      return child;
    });
    return createElement.call(React, type, props, ...children);
  }
}

export interface IXValue {
  get(): any;
  set(value: any): any;
  [reactDisplay]: any;
}


export const XX = (__): IXValue => {
  let access = XObject.lastAccess;
  return {
    get() { return access.obj[access.prop] },
    set(value) {
      return access.obj[access.prop] = value;
    },
    [reactDisplay]: () => access.obj[access.prop]
  }
}
