import React, { Fragment, useContext, useMemo, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { format } from "date-fns";
import { TimelineMonth } from "../components/TimelineMonth";
import { mobileBreakpoint } from "../styleBits";
import { Button, ButtonTheme } from "../components/Button";

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 128px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mobileBreakpoint} {
    margin-top: 24px;
    padding-bottom: 32px;
    text-align: center;
  }

  h2 {
    font-family: BryantWebBold;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -2px;
    text-align: center;
    color: var(--h2);

    ${mobileBreakpoint} {
      margin-top: 48px;
    }
  }
`;

const Navbar = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 8px;

  .title {
    color: var(--icons-text-blue-primary, #110052);
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    /* H4/Desktop/English */
    font-family: BryantWebBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .years {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  ${mobileBreakpoint} {
    padding-left: 0;
  }

  a {
    text-decoration: none;
    font-family: BryantWebBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: var(--timeline-anchor);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobileBreakpoint} {
    padding: 0 8px;
  }
  &.collapsed {
    max-height: 1660px;
    overflow-y: hidden;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75px;
      background: var(--testimonial-fade);
    }
  }
`;

const TimelineFooter = styled.div`
  display: flex;
  place-content: center;
`;

const BadgeWrapper = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 5px);
    bottom: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px dashed var(--timeline-border);
  }
`;

const MonthBadge = styled.div`
  font-family: BryantWebBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  border-radius: 40px;
  padding: 8px 16px;
  background: var(--timeline-badge);
  color: var(--timeline-badge-text);
  width: fit-content;
  position: relative;
  z-index: 1;
`;

const YearBadge = styled.a`
  font-family: Nunito;
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  border-radius: 40px;
  padding: 12px 16px;
  width: fit-content;
  position: relative;
  z-index: 1;
  border: 1px solid var(--stroke-default, rgba(17, 0, 82, 0.64));
  .currentYear & {
    background: var(--flat-comp-bg-blue-selected, rgba(17, 0, 82, 0.07));
  }
`;

function Timeline() {
  const theme: any = useContext(ThemeContext);
  const { loaded, data } = theme;
  const [collapsed, setCollapsed] = useState(true);

  const titlesByYearMonth = useMemo(() => {
    if (!data) return [];

    const shaped = data.map((item: any) => ({
      ...item,
      date: Object.keys(item._meta)
        .map((key) => item._meta[key]?.timestamp)
        .filter(Boolean)
        .sort()[0],
    }));

    // Group by year and month using date-fns
    const grouped = shaped.reduce((acc: any, item: any) => {
      try {
        const year = format(new Date(item?.date), "yyyy");
        const month = format(new Date(item?.date), "MM");

        if (!acc[year]) {
          acc[year] = {};
        }

        if (!acc[year][month]) {
          acc[year][month] = [];
        }

        acc[year][month].push(item);

        return acc;
      } catch (err) {
        return acc;
      }
    }, {});

    return grouped;
  }, [data]);

  if (!loaded) return;

  return (
    <Container>
      <h2>SignUp history</h2>

      <Navbar>
        <div className="title">Year</div>
        <div className="years">
          {Object.keys(titlesByYearMonth)
            .reverse()
            .map((year) => (
              <YearBadge
                key={year}
                href={`#${year}-${Object.keys(titlesByYearMonth[year]).reduce(
                  (a, b) => (a > b ? a : b)
                )}`}
              >
                {year}
              </YearBadge>
            ))}
        </div>
      </Navbar>
      <TimelineContainer className={`${collapsed ? "collapsed" : ""}`}>
        {Object.keys(titlesByYearMonth)
          .reverse()
          .map((year) => (
            <Fragment key={year}>
              {Object.keys(titlesByYearMonth[year])
                .sort()
                .reverse()
                .map((month) => {
                  const monthName = format(new Date(+year, +month - 1), "MMMM");
                  return (
                    <Fragment key={monthName}>
                      <BadgeWrapper>
                        <MonthBadge key={month} id={`${year}-${month}`}>
                          {monthName}
                        </MonthBadge>
                      </BadgeWrapper>
                      <TimelineMonth items={titlesByYearMonth[year][month]} />
                    </Fragment>
                  );
                })}
            </Fragment>
          ))}
      </TimelineContainer>
      <TimelineFooter>
        <Button
          theme={ButtonTheme.light_outline}
          text={collapsed ? "View More" : "View Less"}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        />
      </TimelineFooter>
    </Container>
  );
}

export default Timeline;
