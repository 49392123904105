import React, { Component, ReactNode } from "react";
import styled from "styled-components";
import { component } from "../component";

export enum ButtonTheme {
  primary = "primary",
  secondary1 = "secondary1",
  secondary2 = "secondary2",
  light_outline = "light_outline",
}

@component
export class Button extends Component<{
  url?;
  button?;
  type?;
  id?;
  name?;
  disabled?;
  newWindow?: boolean;
  theme: ButtonTheme;
  text: ReactNode;
  className?: string;
  onClick?;
  ariaPressed?: boolean;
  ariaSelected?;
  ariaControls?;
  role?;
  tabIndex?;
}> {
  static styles = styled.button`
    display: inline-flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 12px;
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;
    gap: 8px;

    &.secondary1 {
      border: 1px solid var(--outline-border);
      color: var(--button-secondary);
      height: 46px;

      &:active,
      &:focus,
      &:hover {
        background: var(--outline-focused);
        outline 1px solid var(--outline-border);
      }
    }

    &.secondary2 {
      border: 1px solid var(--alternative-secondary-button);
      color: var(--alternative-secondary-button);
      background: transparent;
      height: 46px;

      &:active,
      &:focus,
      &:hover {
        background: var(--outline-alternative-focused);
        outline 1px solid var(--alternative-secondary-button);
      }
    }

    &.light_outline {
      border: 1px solid var(--outline-button);
      background-color: transparent;
      color: var(--outline-button);
      height: 46px;

      &:active,
      &:focus,
      &:hover {
        background: var(--outline-focused);
        outline 1px solid var(--button-secondary);
      }
    }

    &.primary {
      background-color: var(--brand-pink);
      color: var(--button-text);
      box-shadow: 0px 4px 16px 4px rgba(125, 60, 0, 0.2);

      &:active,
      &:focus,
      &:hover {
        color: var(--button-text);
        background: var(--primary-focused);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  render(Container?) {
    return (
      <Container
        onClick={this.props.onClick}
        id={this.props.id}
        disabled={this.props.disabled}
        name={this.props.name}
        type={this.props.type ?? "text"}
        as={this.props.button ? "button" : this.props.url && "a"}
        target={this.props.newWindow && "_blank"}
        className={`button ${this.props.theme} ${this.props.className || ""}`}
        aria-pressed={this.props.ariaPressed}
        aria-selected={this.props.ariaSelected}
        aria-controls={this.props.ariaControls}
        role={this.props.role}
        href={this.props.url}
        tabIndex={this.props.tabIndex}
      >
        {this.props.text}
        {this.props.newWindow ? (
          <span className="sr-only">(opens in new window)</span>
        ) : null}
      </Container>
    );
  }
}
