import React from "react";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import useStripePromise from "../../hooks/useStripePromise";

export const Payment = ({ payload, setPayload }) => {
  const stripePromise = useStripePromise();

  const convertCssVariableToHex = (variable) => {
    const computedStyle = getComputedStyle(document.body);
    const value = computedStyle.getPropertyValue(variable);
    return value ? value.trim() : null;
  };

  const appearance = {
    rules: {
      ".Input:focus": {
        outline: convertCssVariableToHex("--input-focus-border"),
      },
      ".Input::placeholder": {
        color: convertCssVariableToHex("--input-placeholder-text-color"),
      },
      ".Input": {
        backgroundColor: convertCssVariableToHex("--input-default-bg"),
        border: convertCssVariableToHex("--input-default-border"),
        boxShadow: convertCssVariableToHex("--input-shadow"),
        color: convertCssVariableToHex("--input-text-color"),
        borderRadius: "16px",
        padding: "13px 16px",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontWeight: "700",
      },
      ".Label": {
        color: convertCssVariableToHex("--input-text-color"),
        fontFamily: "Nunito",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "16px",
        marginLeft: "8px",
      },
    },
  };

  const amount = payload?.amount ?? payload?.customAmount ?? 10;
  return (
    <>
      {stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            mode: payload.isRecurring ? "subscription" : "payment",
            amount: amount * 100,
            currency: "usd",
            appearance,
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap",
              },
            ],
          }}
        >
          <CheckoutForm payload={payload} setPayload={setPayload} />
        </Elements>
      )}
    </>
  );
};
