import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Field } from "../components/Field";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";

import { ImageEnum, cssImg, imgBlob } from "../cssImg";
import Story from "../components/Story";
import StoriesEmptyState from "../components/StoryEmptyState";

const StoryContainer = styled.div`
  margin: 64px auto;
  max-width: 800px;

  a {
    text-decoration: none;
  }

  .page-title {
    width: 100%;
    font-family: "BryantWebBold";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    color: var(--title);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 40px;

    img {
      filter: var(--logo-fingers-filter);
    }
  }

  .pagesCont {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .prev,
    .next {
      cursor: pointer;
      width: 48px;
      height: 48px;
      position: relative;
      border-radius: 50%;
      border: 1px solid var(--outline-border);
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      flex: 0 0 auto;

      .icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: ${cssImg(ImageEnum.arrowForward)};
        background-position: center;
        background-repeat: no-repeat;
        filter: invert(11%) sepia(33%) saturate(7357%) hue-rotate(250deg)
          brightness(68%) contrast(121%);

        ${({ theme }) =>
          theme === "dark" &&
          `
            filter: invert(1);
          `}

        &.next {
        }
      }

      &:hover {
        background-color: var(--outline-focused);
        outline: 1px solid var(--outline-border);
      }
    }

    .prev {
      transform: rotate(180deg);
      margin-right: 14px;
    }
  }

  .pages {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const Stories = (props) => {
  const [original, setOriginal] = useState([]);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const theme: any = useContext(ThemeContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const url =
        window.location.hostname === "localhost"
          ? "https://signup-extension-data.s3.amazonaws.com/stories-dev.json"
          : "https://signup-extension-data.s3.amazonaws.com/stories.json";
      const data = await (await fetch(url)).json();

      const visible = data
        .filter((story) => !story.hidden)
        .sort(
          (a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime()
        );

      const pages = Math.ceil(visible.length / 3);
      setStories(visible);
      setOriginal(visible);
      setPages(pages);
      setLoading(false);
    })();
  }, [setStories, setLoading]);

  useEffect(() => {
    if (search.length === 0) {
      setStories(original);
    } else {
      const fuzzy = new Fuse(original ?? [], {
        keys: ["title", "author", "html"],
      });
      const results = fuzzy.search(search).map((result) => result.item);
      setStories(results);
    }
  }, [original, search]);

  if (loading) {
    return null;
  }

  return (
    <StoryContainer theme={theme}>
      <div className="page-title">
        <img
          role="presentation"
          className="logo"
          width={90}
          height={80}
          src={imgBlob(ImageEnum.logoFingers)}
          alt="Logo"
        />
        SignUp Stories
      </div>
      <Field label="">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Stories"
        />
      </Field>
      <div role="status" className="sr-only">
        {search === "" ? "" : `There are ${stories.length} search results.`}
      </div>
      {search !== "" ? <h2 className="sr-only">Search Results</h2> : null}
      <StoryList>
        {stories.map((story) => {
          return (
            <Link key={story._id} to={`/story/${story._id}`}>
              <Story preview={true} story={story} />
            </Link>
          );
        })}
        {stories.length === 0 ? <StoriesEmptyState search={true} /> : null}
      </StoryList>
      {pages > 1 ? (
        <div className="pagesCont">
          <span
            className="prev"
            onClick={() => {
              if (page === 1) {
                setPage(pages);
              } else {
                setPage(page - 1);
              }
            }}
          >
            <div className="icon"></div>
          </span>
          <div className="pages">
            {page}/{pages}
          </div>
          <span
            className="next"
            onClick={() => {
              if (page === pages) {
                setPage(1);
              } else {
                setPage(page + 1);
              }
            }}
          >
            <div className="icon"></div>
          </span>
        </div>
      ) : null}
    </StoryContainer>
  );
};

export default Stories;
