import { ImageEnum } from "../../cssImg";

export const recurringExclude = ["pick-movie", "pick-episode", "custom"];

const complete_images = {
  happy: {
    light: ImageEnum.happyLight,
    dark: ImageEnum.happyDark,
    high: ImageEnum.happyHigh,
  },
  sad: {
    light: ImageEnum.sadLight,
    dark: ImageEnum.sadDark,
    high: ImageEnum.sadHigh,
  },
};

export const getCompleteImage = (theme, isError) => {
  return isError ? complete_images.sad[theme] : complete_images.happy[theme];
};

const images = {
  gift: {
    light: ImageEnum.giftLight,
    dark: ImageEnum.giftDark,
    high: ImageEnum.giftHigh,
  },
  moviefilm: {
    light: ImageEnum.moviefilmLight,
    dark: ImageEnum.moviefilmDark,
    high: ImageEnum.moviefilmHigh,
  },
  popcorn: {
    light: ImageEnum.popcornLight,
    dark: ImageEnum.popcornDark,
    high: ImageEnum.popcornHigh,
  },
  hearthands: {
    light: ImageEnum.hearthandsLight,
    dark: ImageEnum.hearthandsDark,
    high: ImageEnum.hearthandsHigh,
  },
  question: {
    light: ImageEnum.questionLight,
    dark: ImageEnum.questionDark,
    high: ImageEnum.questionHigh,
  },
  tv: {
    light: ImageEnum.tvLight,
    dark: ImageEnum.tvDark,
    high: ImageEnum.tvHigh,
  },
  wave: {
    light: ImageEnum.waveLight,
    dark: ImageEnum.waveDark,
    high: ImageEnum.waveHigh,
  },
};

export const getTypes = (theme, state) => {
  return [
    {
      id: "price_1OCvfLD9Pbq38Q0JZyCtI26Y",
      amount: 10,
      each: false,
      title: "The Casual Enjoyer",
      description: "A small donation to support the SignUp mission",
      icon: images.popcorn[theme],
    },
    {
      id: "price_1OCvfoD9Pbq38Q0JUjamRNCX",
      amount: 50,
      each: false,
      title: "Frequent Fan",
      description: "Pays for 20% of a movie to be interpreted",
      icon: images.wave[theme],
    },
    {
      id: "price_1OCvgUD9Pbq38Q0JykpljVxp",
      amount: 125,
      each: false,
      title: "The Philanthropist",
      description: "Pays for 50% of a movie to be interpreted",
      icon: images.hearthands[theme],
    },
    {
      id: "price_1OCvgwD9Pbq38Q0J5IuG3WRZ",
      amount: 250,
      each: false,
      title: "The Giver",
      description: "Pays for 100% of a movie to be interpreted",
      icon: images.gift[theme],
    },
    {
      id: "pick-movie",
      amount: 50,
      each: true,
      title: "Pick a Movie",
      description:
        "Choose one or more movies and pay for them to be interpreted for SignUp",
      icon: images.moviefilm[theme],
    },
    {
      id: "pick-episode",
      amount: 20,
      each: true,
      title: "Pick Episodes",
      description:
        "Choose one or more TV episodes and pay for them to be interpreted for SignUp",
      icon: images.tv[theme],
    },
    {
      id: "custom",
      amount: null,
      each: false,
      title: "Custom",
      description: "Donate a custom amount to SignUp",
      icon: images.question[theme],
    },
  ].filter((type) => {
    if (state.isRecurring) {
      return !recurringExclude.includes(type.id);
    } else {
      return true;
    }
  });
};

export const getAmount = (selected, state) => {
  if (!selected) {
    return;
  }

  if (selected.id === "custom") {
    return state?.customAmount;
  } else if (selected.id === "pick-episode") {
    return state?.episodeCount * selected.amount;
  } else if (selected.id === "pick-movie") {
    return state?.movieCount * selected.amount;
  } else {
    return selected.amount;
  }
};
