import React, { useState } from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";

import { TestimonyCard } from "./TestimonyCard";
import { Button, ButtonTheme } from "./Button";
import { mobileBreakpoint } from "../styleBits";

const Container = styled.div`
  .testimonials {
    display: flex;
    width: calc(100% + 24px);
    margin-left: -12px;
    position: relative;
    height: 430px;

    ${mobileBreakpoint} {
      height: auto;
      .showMoreBtn {
        display: none;
      }

      &.showMore {
        &::before {
          content: none;
        }
      }
    }

    &.showMore {
      height: auto;

      &::before {
        content: none;
      }

      .showMoreBtn {
        bottom: 0;
        top: auto;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -65px;
      height: 75%;
      z-index: 1;
      background: var(--testimonial-fade);
    }
  }

  .showMoreBtn {
    position: absolute;
    left: 0;
    right: 0;
    top: 420px;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
  }
`;

export const TestimonyFade = ({ testimonials, theme }) => {
  const [showMore, setShowMore] = useState(false);
  const classNames = `testimonials ${showMore ? "showMore" : ""}`;

  return (
    <Container theme={theme}>
      <Masonry
        className={classNames}
        breakpointCols={3}
        columnClassName="testimonial-column"
      >
        {testimonials.map((testimonial) => (
          <TestimonyCard
            key={testimonial.author}
            testimonial={testimonial}
            width="auto"
          />
        ))}
        <div className="showMoreBtn">
          <Button
            theme={ButtonTheme.primary}
            text={
              showMore
                ? "Show Less"
                : testimonials.length > 3
                ? `View ${testimonials.length - 3} More`
                : "Show More"
            }
            onClick={() => {
              if (showMore) {
                document
                  .querySelector(".first-test-quote")
                  .scrollIntoView({ behavior: "smooth" });
              }
              setShowMore(!showMore);
              gtag(
                "event",
                `testimonial show ${showMore ? "less" : "more"} clicked`
              );
            }}
          />
        </div>
      </Masonry>
    </Container>
  );
};
