import React, { Component } from "react";
import styled from "styled-components";

import { component } from "../../component";
import { XInit } from "../../XObject";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  largeTitleCss_desktop,
  largeTitleClassName,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { pictureUrl, thumbnailUrl } from "../../pictureUrl";
import Format from "../Format";

@component
class MovieTiles extends Component<{ movies: any[] }> {
  static styles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .movies {
      display: flex;
      flex-wrap: wrap;
      ${desktopBreakpoint} {
        margin: 0 -32px 64px;
      }

      .movie {
        aspect-ratio: 317/480;

        ${desktopBreakpoint} {
          width: 317px;
          height: 480px;
          margin: 32px;
        }
        border-radius: 12px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: var(--tile-shadow);
      }

      @media (max-width: 1194px) {
        width: 100%;
        justify-content: space-between;
        .movie {
          width: calc(33% - 8px);
          height: 100%;
          margin: 0 0 16px 0;
        }
      }

      @media (max-width: 691px) {
        .movie {
          width: calc(50% - 8px);
        }
      }

      @media (max-width: 400px) {
        .movie {
          width: 100%;
        }
      }
    }
  `;
  render() {
    return (
      <>
        <div className="movies">
          {this.props.movies.map((movie) => (
            <img
              key={movie.featuredPicture}
              className="movie"
              src={`https://signup-extension-data.s3.amazonaws.com/images/${movie.featuredPicture}`}
              alt={`The box cover for the movie ${movie.title}`}
            />
          ))}
        </div>
      </>
    );
  }
}

const buttonNames = {
  all: "All services",
  disney: "Disney+",
  netflix: "Netflix",
  peacock: "Peacock",
  hotstar: "Hotstar",
  youtube: "YouTube",
};

@component
export class SupportedMoviesSection extends Component<{
  data;
  className?: string;
}> {
  static styles = styled.div`
    text-align: center;

    ${desktopBreakpoint} {
      padding: 128px 0;
    }

    ${mobileBreakpoint} {
      padding: 40px 0;
    }

    .${largeTitleClassName} {
      ${largeTitleCss_desktop}
      display: block;
      text-align: center;
      margin-bottom: 64px;
      color: var(--alternative-header-color);
    }

    ${Button} {
      background: transparent;
    }

    .services {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px 0;

      > *:not(:last-child) {
        margin-right: 8px;
      }
      margin-bottom: 16px;

      ${Button} {
        &:not(.active) {
          border: 1px solid var(--button-inactive-border);
        }

        &.active {
          background: var(--button-active-bg);
          border: 1px solid var(--button-active-border);
        }
      }
    }

    ${innerContCss}

    ${MovieTiles} + ${MovieTiles} {
      ${desktopBreakpoint} {
        margin-top: 96px;
      }
      ${mobileBreakpoint} {
        margin-top: 40px;
      }
    }

    .allMovies {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 40px;
      .movie {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          align-items: center;
        }

        .formats {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
          margin-right: 16px;
          max-width: 140px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 12px;
        }
        height: 64px;
        border-bottom: 1px solid white;
        display: flex;
        align-items: center;
        font-weight: 700;
        width: calc(50% - 16px);
        text-align: left;

        ${mobileBreakpoint} {
          width: 100%;
        }
      }
    }
  `;

  state = XInit(
    class {
      service = "all";
      viewAllTitles = false;
    }
  );

  render() {
    if (!this.props.data) return;

    const movieCountPerService = this.props.data.reduce(
      (acc, movie) => {
        acc.all++;
        for (const service of movie.services) {
          if (!(service in acc)) {
            acc[service] = 1;
          } else {
            acc[service]++;
          }
        }
        return acc;
      },
      { all: 0 } as Record<string, number>
    );

    const services = Object.keys(movieCountPerService);
    const movies = this.props.data.filter(
      (m) =>
        (this.state.service === "all" ||
          m.services?.includes?.(this.state.service)) &&
        m.featured &&
        m.featuredPicture
    );

    const allMovies =
      this.state.service === "all"
        ? this.props.data
        : this.props.data.filter((m) =>
            m.services?.includes?.(this.state.service)
          );

    return (
      <div className="innerCont" id="movies">
        <h2 className={largeTitleClassName}>Supported titles</h2>
        <div
          className="services"
          role="tablist"
          aria-label="Streaming Services"
        >
          {services.map((service) => {
            return (
              <Button
                button={true}
                role="tab"
                ariaSelected={this.state.service === service}
                ariaControls={`${service}-panel`}
                id={service}
                key={service}
                text={`${buttonNames[service] ?? "Others"} (${
                  movieCountPerService[service]
                })`}
                theme={ButtonTheme.secondary2}
                className={this.state.service === service ? "active" : ""}
                onClick={() => (this.state.service = service)}
              />
            );
          })}
        </div>
        {services.map((service, index) => {
          return (
            <div
              tabIndex={index}
              id={`${service}-panel`}
              role="tabpanel"
              aria-labelledby={service}
              key={service}
              style={{
                display: this.state.service === service ? "block" : "none",
              }}
            >
              {movies.length > 0 && <MovieTiles movies={movies} />}
              {(this.state.viewAllTitles || movies.length === 0) && (
                <div className="allMovies">
                  {allMovies.map((m) => (
                    <div key={m.title} className="movie">
                      <div>
                        <img
                          alt={m.title}
                          loading="lazy"
                          width={40}
                          height={40}
                          onError={(e) => {
                            // Fall back if thumbnail doesn't exist
                            e.currentTarget.src = pictureUrl(m.picture);
                          }}
                          src={thumbnailUrl(m._id)}
                        />
                        {m.title}
                      </div>
                      <div className="formats">
                        {m.formats.map((f) => (
                          <Format key={f} format={f} />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}

        {movies.length > 0 && (
          <div className="flex flex-justify-center gap-8">
            <Button
              button={true}
              className="viewAllTitles"
              text={this.state.viewAllTitles ? "Hide list" : "View all titles"}
              theme={ButtonTheme.secondary2}
              onClick={() => {
                this.state.viewAllTitles = !this.state.viewAllTitles;
                gtag("event", "View All Titles Click");
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
