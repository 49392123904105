import { useEffect, useState } from "react";
import { XObject } from "../XObject";
import { state } from "../state";

const useTheme = () => {
  const [theme, setTheme] = useState(
    state.theme === "high-contrast" ? "high" : state.theme
  );

  useEffect(() => {
    const update = () => {
      setTheme(state.theme === "high-contrast" ? "high" : state.theme);
    };

    XObject.observe(state, "theme", update);
    return () => {
      XObject.removeObserver(state, "theme", update);
    };
  }, []);

  return [theme, state.theme];
};

export default useTheme;
