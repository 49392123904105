// import { ImageEnum } from "./library/ImageEnum";

export enum ImageEnum {
  logo = "logo",

  logoFingers = "fingers",
  logoText = "signup-text",

  close = "close",
  letter = "letter",

  donut = "donut.png",
  zootopia = "zootopia.png",
  moana = "moana.png",
  aboutUsThumbASL = "about-us-thumb-asl.png",
  aboutUsThumbASL2x = "about-us-thumb-asl@2x.png",
  aboutUsThumbBSL = "about-us-thumb-bsl.png",
  aboutUsThumbBSL2x = "about-us-thumb-bsl@2x.png",
  aboutUsThumbISL = "about-us-thumb-isl.png",
  aboutUsThumbISL2x = "about-us-thumb-isl@2x.png",

  toggle = "toggle",

  aboutUs_mariella = "aboutUs/mariella.png",
  aboutUs_debiPomerantz = "aboutUs/debi-pomerantz.png",
  aboutUs_elizabethCorbin = "aboutUs/elizabeth-corbin.png",
  aboutUs_gerryDAngelo = "aboutUs/gerry-d-angelo.png",
  aboutUs_harrietSettler = "aboutUs/harriet-settler.png",
  aboutUs_larryGoldberg = "aboutUs/larry-goldberg.png",
  aboutUs_matthewCorbin = "aboutUs/matthew-corbin.png",
  aboutUs_qasimRashid = "aboutUs/qasim-rashid.png",
  aboutUs_stratosphereDigital = "aboutUs/stratosphere-digital.png",
  aboutUs_angelaMcSwain = "aboutUs/angela-mcswain.png",

  aboutUs_stratosphere = "aboutUs/stratosphere-digital.png",

  covers_encanto = "covers/encanto.png",
  covers_frozen2 = "covers/frozen-2.png",
  covers_frozen = "covers/frozen.png",
  covers_homeAlone = "covers/home-alone.png",
  covers_insideOut = "covers/inside-out.png",
  covers_luca = "covers/luca.png",
  covers_moana = "covers/moana.png",
  covers_soul = "covers/soul.png",
  covers_theIncreibles = "covers/the-incredibles.png",
  covers_theLionKing = "covers/the-lion-king.png",
  covers_theLittleMermaid = "covers/the-little-mermaid.png",
  covers_zootopia = "covers/zootopia.png",

  press_bbc = "press/bbc.png",
  press_j14 = "press/j14.png",
  press_mashable = "press/mashable.png",
  press_theOptimistDaily = "press/the-optimist-daily.png",
  press_theTimes = "press/the-times.png",
  press_upworthy = "press/upworthy.png",
  press_variety = "press/variety.png",
  press_yahooFinance = "press/yahoo-finance.png",

  workWithUs1 = "work-with-us-1.png",
  workWithUs2 = "work-with-us-2.png",

  donation_1 = "makeADonation/1.png",
  donation_2 = "makeADonation/2.png",
  donation_3 = "makeADonation/3.png",
  donation_4 = "makeADonation/4.png",

  socials_facebook = "facebook",
  socials_instagram = "instagram",
  socials_twitter = "twitter",
  socials_linkedIn = "linkedin",

  arrowForward = "arrow_forward",

  downArrowLight = "expand_more-light",
  downArrowDark = "expand_more-dark",

  menu = "menu",

  signupSad = "signup-sad",

  giftLight = "gift-light",
  giftDark = "gift-dark",
  giftHigh = "gift-high",
  moviefilmLight = "moviefilm-light",
  moviefilmDark = "moviefilm-dark",
  moviefilmHigh = "moviefilm-high",
  popcornLight = "popcorn-light",
  popcornDark = "popcorn-dark",
  popcornHigh = "popcorn-high",
  hearthandsLight = "hearthands-light",
  hearthandsDark = "hearthands-dark",
  hearthandsHigh = "hearthands-high",
  questionLight = "question-light",
  questionDark = "question-dark",
  questionHigh = "question-high",
  tvLight = "tv-light",
  tvDark = "tv-dark",
  tvHigh = "tv-high",
  sadLight = "sad-light",
  sadDark = "sad-dark",
  sadHigh = "sad-high",
  waveLight = "wave-light",
  waveDark = "wave-dark",
  waveHigh = "wave-high",

  happyLight = "happy-light",
  happyDark = "happy-dark",
  happyHigh = "happy-high",

  radioButtonUnchecked = "radio_button_unchecked",
  radioButtonChecked = "radio_button",

  subtract = "minus",
  add = "add",
  giving = "giving",
}

export const imgBlob = (() => {
  const _cache: any = {};
  return (name: ImageEnum, colors?: { [color: string]: string }) => {
    const key = name + JSON.stringify(colors);
    if (_cache[key]) return _cache[key];
    return (_cache[key] = URL.createObjectURL(
      new Blob([img(name, colors)], { type: "image/svg+xml" })
    ));
  };
})();

export function cssImg(name: ImageEnum, colors?: any) {
  if (!name) return "";
  if (name.endsWith(".png")) {
    return `url(${require(`./images/${name}`)})`;
  } else {
    return `url('${imgBlob(name, colors)}')`;
  }
}

export const img = (() => {
  const _cache: any = {};
  return (name: ImageEnum, colors?: { [color: string]: string }) => {
    const key = name + JSON.stringify(colors);
    if (_cache[key]) return _cache[key];
    let src: string = require(`./images/${name}.svg`);
    if (colors) {
      for (const color in colors) {
        src = src.replace(
          new RegExp(color.replace("$", "\\$"), "g"),
          colors[color]
        );
      }
    }
    return (_cache[key] = src);
  };
})();
