import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { XInit } from "../../XObject";
import { cmsFields } from "../../cmsFields";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
  largeTitleCss_desktop,
  largeTitleCss_mobile,
  largeTitleClassName,
} from "../../styleBits";
import { cms } from "../../cms";
import { ImageEnum, cssImg } from "../../cssImg";
import { state } from "../../state";

@component
class Question extends Component<{
  id;
  question;
  answer;
  opened;
  onToggle;
  index;
}> {
  static styles = styled.div`
    position: relative;
    padding: 24px 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      width: 100%;
      color: var(--subtitle);
    }

    .question {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }

    .toggle {
      cursor: pointer;
      width: 48px;
      height: 48px;
      position: relative;
      border-radius: 50%;
      border: 1px solid var(--outline-border);
      transform: rotate(180deg);
      transition: transform 0.3s ease;
      flex: 0 0 auto;

      .icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: ${cssImg(ImageEnum.toggle)};
        background-position: center;
        background-repeat: no-repeat;
        filter: invert(11%) sepia(33%) saturate(7357%) hue-rotate(250deg)
          brightness(68%) contrast(121%);

        ${({ theme }) =>
          theme === "dark" &&
          `
            filter: invert(1);
          `}
      }

      &:hover {
        background-color: var(--outline-focused);
        outline: 1px solid var(--outline-border);
      }
    }

    &.opened .toggle {
      transform: rotate(0deg);
    }

    .answer-container {
      display: grid;
      grid-template-rows: min-content 0fr;
      transition: grid-template-rows 500ms;
      &.opened {
        grid-template-rows: min-content 1fr;
      }
      &:before {
        content: "";
      }
    }
    .answer {
      margin-top: 26px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      display: block;
      overflow: hidden;
    }
  `;

  state = XInit(class {});

  render(Container?) {
    return (
      <Container className={this.props.opened && "opened"} theme={state.theme}>
        <button
          aria-expanded={this.props.opened ? "true" : "false"}
          aria-controls={`content_${this.props.id}`}
          id={this.props.id + "_question"}
          onClick={() => {
            this.props.onToggle(this.props.id);
            gtag("event", "FAQ Collapsed", {
              event_category: "FAQ",
              event_label: this.props.question,
            });
          }}
        >
          <h3 className="top">
            <span className="question">{this.props.question}</span>
            <span className="toggle">
              <div className="icon"></div>
            </span>
          </h3>
        </button>
        <div
          className={`answer-container ${
            this.props.opened ? "opened" : "closed"
          }`}
        >
          <p
            className="answer"
            id={`content_${this.props.id}`}
            aria-hidden={!this.props.opened ? "true" : "false"}
          >
            {this.props.answer}
          </p>
        </div>
      </Container>
    );
  }
}

@component
export class FrequentlyAskedQuestionsSection extends Component {
  static styles = styled.div`
    ${desktopBreakpoint} {
      padding: 128px 0;
    }
    ${mobileBreakpoint} {
      padding: 48px 0;
    }

    ${innerContCss}

    .${largeTitleClassName} {
      ${desktopBreakpoint} {
        ${largeTitleCss_desktop}
        margin-bottom: 64px;
      }
      ${mobileBreakpoint} {
        ${largeTitleCss_mobile}
        margin-bottom: 24px;
      }
      display: block;
      text-align: center;
      color: var(--h2);
    }

    ${Question}:not(:last-child) {
      ${desktopBreakpoint} {
        margin-bottom: 32px;
      }
      ${mobileBreakpoint} {
        margin-bottom: 16px;
      }
    }

    ${Question} {
      cursor: pointer;
      border-bottom: 1px solid var(--faq-border-color);
    }
  `;

  state = XInit(
    class {
      opened;
      faq;
    }
  );

  componentDidMount(): void {
    this.state.faq = cms(cmsFields.FAQ).map((f) => {
      return {
        _id: f._id,
        question: f[cmsFields.FAQ_Question],
        answer: f[cmsFields.FAQ_Answer],
      };
    });
    this.state.opened = this.state.faq[0]._id;
  }

  render() {
    const toggle = (id) => {
      if (this.state.opened === id) {
        this.state.opened = null;
      } else {
        this.state.opened = id;
      }
    };

    return (
      <div className="innerCont" id="faqs">
        <h2 className={largeTitleClassName}>Frequently asked questions</h2>
        {this.state.faq?.map?.((f, index) => (
          <Question
            key={f._id}
            id={f._id}
            index={index}
            question={f.question}
            answer={f.answer}
            opened={this.state.opened === f._id}
            onToggle={toggle}
          />
        ))}
      </div>
    );
  }
}
