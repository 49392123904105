import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ImageEnum, cssImg } from "../cssImg";
import { state } from "../state";

const Button = styled.button`
  display: inline-flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 24px;
  padding: 0 12px;
  font-weight: 800;
  font-size: 19px;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  border: none;
  cursor: pointer;
  border: 1px solid var(--outline-border);
  color: var(--button-secondary);
  background: transparent;
  position: relative;

  &:active,
  &:focus,
  &:hover {
    background: var(--outline-focused);
    outline 1px solid var(--outline-border);
  }

  .arrow {
    height: 24px;
    width: 24px;
    background-image: ${cssImg(ImageEnum.downArrowLight)};

    ${(props) => {
      if (props.theme === "dark") {
        return `background-image: ${cssImg(ImageEnum.downArrowDark)};`;
      }
      return "";
    }}
  }
`;

const Dropdown = styled.fieldset`
  position: absolute;
  top: 110%;
  right: 0;
  width: 280px;
  background: var(--surface-bg-100);
  border-radius: 16px;
  box-shadow: 0px 12px 40px 8px rgba(125, 60, 0, 0.3);
  accent-color: var(--misc-blue-heavy);
  margin: 0;
  padding: 0;
  border: none;

  legend {
    display: none;
  }

  ${(props) => {
    if (props.theme === "dark") {
      return `
      accent-color: var(--misc-white-heavy);
      box-shadow: 0px 12px 40px 8px rgba(6, 0, 31, 0.80);
      `;
    }
    return "";
  }}

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px;
    border-bottom: 1px solid var(--shade-300);
    font-size: 18px;

    ${(props) => {
      if (props.theme === "dark") {
        return `
        border-bottom: 1px solid #837aa6;
        `;
      }
      return "";
    }}

    input[type="radio"] {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

interface DropdownButtonProps {
  options: { label: string; value: string }[];
  onChange?: (value) => void;
  text: string;
  value: string;
  className?: string;
}

export const DropdownButton = ({
  options,
  value,
  onChange,
  text,
  className,
}: DropdownButtonProps) => {
  const theme = state.theme;
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [ref]);

  useEffect(() => {
    if (active) {
      const radio = ref.current.querySelector("input:checked");
      radio?.focus();
    }
  }, [active]);

  return (
    <Button
      className={className}
      onClick={() => setActive(!active)}
      theme={theme}
      aria-expanded={active ? "true" : "false"}
    >
      {text}
      <div className="arrow" />

      {active ? (
        <Dropdown ref={ref} theme={theme}>
          <legend>Choose a theme</legend>
          {options.map((option) => (
            <div
              key={option.value}
              className="option"
              onClick={(e) => {
                e.stopPropagation();
                onChange(option.value);
              }}
            >
              <label htmlFor={option.value}>{option.label}</label>
              <input
                type="radio"
                id={option.value}
                name="theme"
                checked={option.value === value}
                onChange={() => {}}
              />
            </div>
          ))}
        </Dropdown>
      ) : null}
    </Button>
  );
};
