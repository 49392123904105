import React from "react";
import styled from "styled-components";
import { mobileBreakpoint } from "../styleBits";

const Container = styled.div`
  .page-title {
    color: var(--title);
    text-align: center;
    font-family: BryantWebBold;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -3px;

    ${mobileBreakpoint} {
      font-size: 50px;
    }
  }

  .page-subtitle {
    color: var(--title);
    text-align: center;
    font-family: BryantWebBold;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -2px;
    margin-top: 16px;
    margin-bottom: 40px;

    ${mobileBreakpoint} {
      font-size: 28px;
      line-height: 28px;
      padding: 0 16px;
    }
  }

  .donation-video {
    margin: 0 auto;
    width: 800px;
    height: 450px;
    border-radius: 24px;
    background: #121214;
    margin-bottom: 40px;
    background: url("/donation-bg.png");

    ${mobileBreakpoint} {
      width: calc(100% - 32px);
      height: 200px;
    }
  }

  .donation-details {
    color: var(--title);
    font-family: BryantWebBold;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    margin-bottom: 16px;

    ${mobileBreakpoint} {
      font-size: 26px;
      line-height: 30px;
      padding: 0 16px;
    }
  }

  .donation-details-subtext {
    color: var(--subtitle);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 50px;

    ${mobileBreakpoint} {
      padding: 0 16px;
    }
  }
`;

export const DonationContent = () => {
  return (
    <Container>
      <div className="page-title">Donations</div>
      <div className="page-subtitle">
        Help us interpret more movies and TV shows into sign language
      </div>
      <div className="donation-video"></div>
      <div className="donation-details">
        We are providing an essential resource for the Deaf community and we
        need your help.
      </div>
      <div className="donation-details-subtext">
        SignUp is a free Google Chrome Extension that overlays a sign language
        interpreter on Disney+ and Netflix. Available with a simple one-click
        installation SignUp provides full media accessibility on streaming
        platforms for the Deaf community for the very first time.
      </div>
    </Container>
  );
};
