import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import StoryPreview from "./StoryPreview";

function extractVideoID(url) {
  let videoId = null;
  if (url.includes("youtu.be/")) {
    videoId = url.split("youtu.be/")[1];
  } else if (url.includes("watch?v=")) {
    videoId = url.split("watch?v=")[1].split("&")[0];
  }
  return videoId;
}

const StoryContainer = styled.div`
  img,
  iframe {
    width: 100%;
    border-radius: 8px;
  }

  img {
    object-fit: cover;
  }

  iframe {
    border: none;
  }

  .story-content {
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: var(--subtitle);

    a {
      color: var(--link-color);
    }

    .textUnderline {
      text-decoration: underline;
    }

    .textBold {
      font-weight: bold;
    }

    .textItalic {
      font-style: italic;
    }
  }
`;

const Title = styled.div`
  font-family: "BryantWebBold";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1px;
  color: var(--title);
  text-decoration: none;
  margin-top: 16px;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: var(--subtitle);

  .date {
    color: var(--timeline-anchor);
  }
`;

const StoryMedia = ({ media }) => {
  if (media.type === "image") {
    return <img src={media.value} alt="" />;
  }

  const embedId = extractVideoID(media.value);
  return (
    <iframe
      width="100%"
      height="100%"
      style={{ aspectRatio: "9/6 auto" }}
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};

interface StoryProps {
  preview: boolean;
  story: {
    _id: string;
    title: string;
    content: string;
    html: string;
    media: string;
    date: string;
    author?: string;
  };
}

const Story: React.FC<StoryProps> = ({ preview, story }) => {
  return (
    <StoryContainer>
      {story.media ? <StoryMedia media={story.media} /> : null}
      <Title>{story.title}</Title>
      <Details>
        <div>{story.author ? `By ${story.author}` : null}</div>
        <div className="date">
          {story.date ? format(new Date(story.date), "MM/dd/yy") : null}
        </div>
      </Details>
      {preview ? (
        <StoryPreview>
          <div
            className="story-content"
            dangerouslySetInnerHTML={{
              __html: story.html,
            }}
          ></div>
        </StoryPreview>
      ) : (
        <div
          className="story-content"
          dangerouslySetInnerHTML={{
            __html: story.html,
          }}
        ></div>
      )}
    </StoryContainer>
  );
};

export default Story;
