import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../component";
import classnames from "classnames";
import { ButtonTheme } from "./Button";

@component
export class RadioButton extends Component<{
  id?;
  theme: ButtonTheme;
  checked: boolean;
  className?: string;
  onClick?;
  children?;
}> {
  static styles = styled.div`
    display: inline-flex;
    min-height: 46px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 12px;
    font-weight: 800;
    font-size: 19px;
    line-height: 24px;
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;

    input {
      width: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }

    &.slim {
      min-height: 20px;
      padding: 0 8px;
    }

    &.secondary:focus-within {
      outline: 1px solid var(--outline-border);
    }

    &.light_outline:focus-within {
      outline: 1px solid var(--button-secondary);
    }

    &.secondary2:focus-within {
      outline: 1px solid var(--alternative-secondary-button);
    }

    &.secondary1 {
      border: 1px solid var(--outline-border);
      color: var(--button-secondary);
      height: 46px;
      &.slim {
        height: 20px;
      }

      &.checked,
      &:active,
      &:focus,
      &:hover {
        background: var(--outline-focused);
      }
    }

    &.secondary2 {
      border: 1px solid var(--alternative-secondary-button);
      color: var(--alternative-secondary-button);
      background: transparent;
      height: 46px;
      &.slim {
        height: 20px;
      }

      &.checked,
      &:active,
      &:focus,
      &:hover {
        background: var(--outline-alternative-focused);
      }
    }

    &.light_outline {
      border: 1px solid var(--outline-button);
      background-color: transparent;
      color: var(--outline-button);
      height: 46px;
      &.slim {
        height: 20px;
      }

      &.checked,
      &:active,
      &:focus,
      &:hover {
        background: var(--outline-focused);
        outline 1px solid var(--button-secondary);
      }
    }

    &.primary {
      background-color: var(--brand-pink);
      color: var(--button-text);
      box-shadow: 0px 4px 16px 4px rgba(125, 60, 0, 0.2);

      .checked,
      &:active,
      &:focus,
      &:hover {
        color: var(--button-text);
        background: var(--primary-focused);
      }
    }
  `;

  render(Container?) {
    return (
      <Container
        id={this.props.id}
        onClick={this.props.onClick}
        className={classnames(
          "button",
          this.props.theme,
          this.props.className,
          { checked: this.props.checked }
        )}
      >
        {this.props.children}
      </Container>
    );
  }
}
