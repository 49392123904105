import React from "react";
import styled from "styled-components";
import { pictureUrl } from "../pictureUrl";
import { mobileBreakpoint } from "../styleBits";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    right: 0;
    width: 1px;
    height: calc(100% + 38px);
    border-left: 1px dashed var(--timeline-border);
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 4px);
    bottom: -29px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px dashed var(--timeline-border);
    z-index: 1;
  }
`;

const Items = styled.div<{ offset?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.offset ? "flex-start" : "flex-end")};
  margin-top: ${(props) => (props.offset ? "88px" : "0")};
  gap: 16px;
`;

const Item = styled.div<{ offset?: boolean }>`
  border-radius: 16px;
  border: 1px solid var(--timeline-item-border);
  background: var(--timeline-item-bg);
  gap: 16px;
  width: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  text-align: center;

  ${mobileBreakpoint} {
    min-width: 60%;
  }

  img {
    border: 1px solid var(--timeline-item-border);
    width: 96px;
    height: 96px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 50%;
  }
`;

export const TimelineItem = ({ item }: { item: any }) => {
  return (
    <Item>
      <img loading="lazy" alt={item.title} src={pictureUrl(item.picture)} />
      <div>{item.title}</div>
    </Item>
  );
};

export const TimelineMonth = ({ items }) => {
  const split = Math.ceil(items.length / 2);
  const left = items.slice(0, split);
  const right = items.slice(split);
  return (
    <Container>
      <Items>
        {left.map((item) => (
          <TimelineItem key={item.title} item={item} />
        ))}
      </Items>
      <Items offset="true">
        {right.map((item) => (
          <TimelineItem key={item.title} item={item} />
        ))}
      </Items>
    </Container>
  );
};
