import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { ImageEnum, imgBlob } from "../../cssImg";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerContCss,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { state } from "../../state";

@component
export class Footer extends Component {
  static styles = styled.div`
    background-color: var(--alternative-section-bg);

    ${innerContCss}

    .innerCont {
      ${desktopBreakpoint} {
        padding-top: 48px;
        padding-bottom: 24px;
      }

      ${mobileBreakpoint} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 0;
      }
    }

    .top {
      display: flex;
      ${desktopBreakpoint} {
        align-items: center;
        margin-bottom: 16px;
      }
      ${mobileBreakpoint} {
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;
        > * {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }

      #footer-logo {
        display: flex;
        border: 2px solid transparent;
        padding: 4px;

        &:hover,
        &:focus {
          outline: 2px solid var(--footer-outline-border);
          background: var(--footer-outline-focused);
          border-radius: 16px;
        }
      }

      .logo {
        filter: invert(1);

        ${({ theme }) =>
          theme === "high-contrast" &&
          `
            filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg)
            brightness(79%) contrast(91%);
          `}
      }

      .socials {
        display: flex;

        ${desktopBreakpoint} {
          margin-left: auto;
        }

        ${mobileBreakpoint} {
          justify-content: center;
        }

        > * {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          padding: 12px;

          &:hover,
          &:focus {
            outline: 2px solid var(--footer-outline-border);
            background: var(--footer-outline-focused);
            border-radius: 50%;
          }

          img {
            filter: invert(1);

            ${({ theme }) =>
              theme === "high-contrast" &&
              `
              filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg)
              brightness(79%) contrast(91%);
            `}
          }
        }
      }

      .donate {
        ${desktopBreakpoint} {
          margin-left: 24px;
        }
      }
    }

    &,
    a {
      color: white;
      ${({ theme }) => {
        if (theme === "high-contrast") {
          return `
            color: var(--brand-blue);
          `;
        }
        return "";
      }}
    }

    a {
      color: white;
      text-decoration: none;
      ${({ theme }) => {
        if (theme === "high-contrast") {
          return `
            color: var(--brand-blue);
          `;
        }
        return "";
      }}
    }

    .nav {
      list-style: none;
      margin: 0;
      padding: 0;

      ${desktopBreakpoint} {
        height: 48px;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      a {
        padding: 12px 16px;
        &:first-child {
          margin-left: -16px;
        }

        &:hover,
        &:focus {
          outline: 2px solid var(--footer-outline-border);
          background: var(--footer-outline-focused);
          border-radius: 24px;
        }
      }

      ${mobileBreakpoint} {
        > * {
          display: block;
          text-align: center;
          height: 48px;
          line-height: 48px;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .bottom {
      display: flex;

      ${desktopBreakpoint} {
        justify-content: space-between;
        align-items: center;
        height: 48px;
        width: 100%;
      }

      ${mobileBreakpoint} {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 8px;
      }

      .copyright {
        order: 1;
        ${mobileBreakpoint} {
          span {
            display: block;
          }
        }
      }

      .links {
        ${desktopBreakpoint} {
          margin-left: auto;
          > *:not(:last-child) {
            margin-right: 24px;
          }
        }
        ${mobileBreakpoint} {
          margin-bottom: 16px;
          > * {
            height: 48px;
            line-height: 48px;
            :not(:last-child) {
              margin-right: 24px;
            }
          }
        }
      }
    }
  `;

  render(Container?) {
    return (
      <Container theme={state.theme}>
        <footer className="innerCont">
          <h2 className="hidden">Footer</h2>
          <div className="top">
            <a href="/" id="footer-logo">
              <img
                className="logo"
                width={114}
                height={40}
                src={imgBlob(ImageEnum.logo)}
                alt="SignUp logo, opens home page"
              />
            </a>
            <div className="socials">
              <a
                aria-label="Follow us on Twitter; opens in a new tab"
                href="https://twitter.com/SignUpCaptions"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter"
              >
                <img src={imgBlob(ImageEnum.socials_twitter)} alt="" />
                <span role="presentation" className="hidden">
                  Twitter
                </span>
              </a>
              <a
                aria-label="Follow us on Instagram; opens in a new tab"
                href="https://www.instagram.com/signupcaptions/"
                target="_blank"
                rel="noopener noreferrer"
                className="instagram"
              >
                <img src={imgBlob(ImageEnum.socials_instagram)} alt="" />
                <span role="presentation" className="hidden">
                  Instagram
                </span>
              </a>
              <a
                aria-label="Follow us on Facebook; opens in a new tab"
                href="https://www.facebook.com/signupcaptions"
                target="_blank"
                rel="noopener noreferrer"
                className="facebook"
              >
                <img src={imgBlob(ImageEnum.socials_facebook)} alt="" />
                <span role="presentation" className="hidden">
                  Facebook
                </span>
              </a>
              <a
                aria-label="Follow us on Linkedin; opens in a new tab"
                href="https://www.linkedin.com/in/mariella-satow/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin"
              >
                <img src={imgBlob(ImageEnum.socials_linkedIn)} alt="" />
                <span role="presentation" className="hidden">
                  Linkedin
                </span>
              </a>
            </div>
            <Button
              theme={ButtonTheme.secondary2}
              text="Donate"
              className="donate"
              type="a"
              url="https://www.gofundme.com/f/help-fund-signup-captions-for-disney-plus"
              newWindow
              onClick={() => {
                gtag("event", "Footer Donate Click");
              }}
            />
          </div>
          <nav aria-label="Footer links">
            <ul className="nav">
              <li className="navItem">
                <a href="/#movies">Movies</a>
              </li>
              <li className="navItem">
                <a href="/#faqs">FAQs</a>
              </li>
              {/* <span className="navItem"><a href="#our-team">Our Team</a></span> */}
              <li className="navItem">
                <a href="/#press">Press</a>
              </li>
              <li className="navItem">
                <a href="/#work-with-us">Work With Us</a>
              </li>
              <li className="navItem">
                <a href="/stories">Stories</a>
              </li>
            </ul>
          </nav>

          <div className="bottom">
            <div className="copyright">
              <span>Copyright © 2024 SignUp Media. </span>
              <span>All Rights Reserved.</span>
            </div>
          </div>
        </footer>
      </Container>
    );
  }
}
