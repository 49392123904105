import React from "react";
import { Field } from "../Field";
import { DropdownSelect } from "../DropdownSelect";
import { languageOptions } from "../../data/languages";
import { streamingOptions } from "../../data/streamers";

const languages = languageOptions.filter((option) => option.value !== "Other");

export const PlatformPicker = ({
  language,
  setLanguage,
  streamer,
  setStreamer,
}) => {
  return (
    <>
      <Field id="streamer" label="Streaming Platform">
        <DropdownSelect
          id="streamer"
          options={streamingOptions}
          value={streamer}
          onChange={(value) => setStreamer(value)}
        />
      </Field>
      <Field id="language" label="Interpretation language">
        <DropdownSelect
          id="language"
          options={languages}
          value={language}
          onChange={(value) => setLanguage(value)}
        />
      </Field>
    </>
  );
};
