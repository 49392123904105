import React from "react";
import styled from "styled-components";
import { imgBlob } from "../cssImg";
import { mobileBreakpoint } from "../styleBits";

const Container = styled.button`
  box-sizing: inherit;
  width: calc(25% - 62px);
  padding: 24px;
  height: 250px;
  border-radius: 8px;
  border: 1px solid var(--donation-item-border);
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${mobileBreakpoint} {
    width: 100%;
  }

  &.selected {
    border: 1px solid var(--donation-item-border);
    background: var(--donation-item-bg-selected);
  }

  img {
    width: 144px;
    height: 120px;
    margin: 0 auto;
  }

  .donation-item-amount {
    color: var(--title);
    text-align: center;
    font-family: BryantWebBold;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }

  .donation-item-title {
    color: var(--title);
    text-align: center;
    font-family: BryantWebBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .donation-item-description {
    color: var(--subtitle);
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const DonationItem = ({ selected, onClick, donation }) => {
  const selectedClass = selected === donation.id ? "selected" : "";
  return (
    <Container className={selectedClass} onClick={onClick}>
      <img src={imgBlob(donation.icon)} alt={donation.title} />
      <div className="donation-item-amount">
        ${donation.amount ?? "XX"}
        {donation.each ? "/ea" : ""}
      </div>
      <div className="donation-item-title">{donation.title}</div>
      <div className="donation-item-description">{donation.description}</div>
    </Container>
  );
};
