export const cmsFields = {
  'Header_Title': '579292b2-50e9-5d9d-a01c-692ac45e5208',
  'Header_Subtitle': 'b1dbe7b8-f2d7-5416-bdc2-bad765dd041f',
  'Header_Photo1': 'ede90c8c-8237-5ad7-9e9f-de56c19fae00',
  'Header_Photo2': 'f0a9980d-bf32-5224-ae2e-2a4637f52287',
  'Header_Photo3': 'e584119c-904e-5c93-8154-1893228cc0f0',
  'AboutUs_Title': 'a33d0098-f76c-582e-9b74-2c06ff293ec0',
  'AboutUs_Text': 'd1faaa80-2e59-594a-9dd3-9d04daa01ab4',
  'Testimonials': '509f2569-b3c7-5fa5-a69d-63452dab864d',
  'Testimonials_Author': 'fadcf75a-e110-5ec7-ab19-b14be45beaf4',
  'Testimonials_Text': 'f1b5b2e1-5b1f-5b1f-8b1f-5b1f5b1f5b1f',
  'FAQ': 'f613752b-1203-5891-b1c9-581735e41487',
  'FAQ_Question': 'c0a649e7-631b-5ed9-a88c-63552a309998',
  'FAQ_Answer': 'bf393542-d1c3-5050-beeb-2e40ec75917b',
  'Press': 'ce7b583d-5057-5fb8-ad3e-fa36534d3514',
  'Press_Picture': '76145e85-517e-5f94-86c4-47c87ddb16f0',
  'Press_URL': '176f901f-45dd-5f52-9a0b-89500b9f6a46',
  'WorkWithUs_Title': '80b2a8b4-8f7e-56c1-a18f-315a086699c4',
  'WorkWithUs_Text': '7cca4707-3b7e-5d89-b45c-5f244b4d97be',
  'WorkWithUs_URL': '39140749-5652-512c-a170-2a3db1091ab2',
  'WorkWithUs_Photo1': '21403731-ab53-5ee5-bd96-147bfc8d4027',
  'WorkWithUs_Photo2': 'f97280bb-e67f-5b97-bf30-d9f28f5efc58',
  'GetInTouch_Title': '96926c22-3eb9-5f51-a98a-bc7eb761d611',
  'GetInTouch_Text': '738f8f58-0248-5ab1-8a9a-f8a831fdf312',
}









