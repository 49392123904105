import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Switch from "../components/Switch";
import { Field } from "../components/Field";
import { ImageEnum, imgBlob } from "../cssImg";
import { Payment } from "../components/stripe/Payment";
import useTheme from "../hooks/useTheme";
import {
  getAmount,
  getTypes,
  recurringExclude,
} from "../components/stripe/utils";
import { DonationItem } from "../components/DonationItem";
import { DonationContent } from "../components/DonationContent";
import { PlatformPicker } from "../components/stripe/PlatformPicker";
import { mobileBreakpoint } from "../styleBits";
import { useLocation } from "react-router-dom";
import { DropdownSelect } from "../components/DropdownSelect";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  padding-bottom: 64px;
  max-width: 1080px;

  ${mobileBreakpoint} {
    padding: 0 16px 64px 16px;
  }

  .donation-action-header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      color: var(--title);
      font-family: BryantWebBold;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    > div:last-child {
      padding: 0 16px;
      color: var(--subtitle);
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .donation-items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .form-header {
    color: var(--title);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    margin-top: 40px;
  }

  ${Field} {
    margin-bottom: 4px;

    label {
      font-family: Nunito;
    }
  }

  ${DropdownSelect} {
    input,
    textarea,
    button {
      font-family: Nunito;
    }
  }

  .season-picker {
    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      width: 100px;
    }
  }

  .donate-button {
    border: none;
    background: none;
    height: 32px;
    width: 32px;
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #11005212;
      border-radius: 50%;
    }

    img {
      width: 24px;
      height: 24px;
      filter: var(--donate-icon-filters);
    }
  }

  .submit-button {
    margin-top: 40px;

    img {
      filter: var(--donate-button-icon);
    }
  }
`;

const Donate = () => {
  const [theme] = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pay = queryParams.get("pay");
  const defaultSelected =
    pay === "true" ? "pick-movie" : "price_1OCvfLD9Pbq38Q0JZyCtI26Y";
  const [donationState, setDonationState] = useState({
    isRecurring: false,
    selected: defaultSelected,
    episodeCount: 1,
    movieCount: 1,
    customAmount: undefined,
    movieTitles: "",
    seriesName: "",
    seasonNumber: "",
    episodeNumbers: "",
    language: "ASL",
    streamer: "Netflix",
  });

  const DONATION_TYPES = useMemo(
    () => getTypes(theme, donationState),
    [theme, donationState]
  );

  const selected = DONATION_TYPES.find(
    (type) => donationState.selected === type.id
  );

  const amount = getAmount(selected, donationState);

  const [payload, setPayload] = useState({
    ...selected,
    amount,
    ...donationState,
  });

  useEffect(() => {
    if (
      donationState.isRecurring &&
      recurringExclude.includes(donationState.selected)
    ) {
      setDonationState({
        ...donationState,
        selected: DONATION_TYPES[0].id,
      });
    }
  }, [donationState, DONATION_TYPES]);

  useEffect(() => {
    setPayload({
      ...selected,
      amount,
      ...donationState,
    });
  }, [selected, amount, donationState]);

  return (
    <Container>
      <DonationContent />

      <div className="donation-action-header">
        <div>Donation amount</div>
        <div>
          Make recurring
          <Switch
            value={donationState.isRecurring}
            onChange={() =>
              setDonationState({
                ...donationState,
                isRecurring: !donationState.isRecurring,
              })
            }
          />
        </div>
      </div>

      <div className="donation-items">
        {DONATION_TYPES.map((donation) => {
          return (
            <DonationItem
              key={donation.id}
              onClick={() => {
                setDonationState({ ...donationState, selected: donation.id });
              }}
              donation={donation}
              selected={donationState.selected}
            />
          );
        })}
      </div>

      <div className="donation-form">
        {donationState.selected === "pick-movie" ? (
          <>
            <div className="form-header">Request information</div>
            <div className="flex gap-8 w100 season-picker">
              <Field label="Title Name">
                <input
                  type="text"
                  value={donationState.movieTitles}
                  onChange={(e) => {
                    setDonationState({
                      ...donationState,
                      movieTitles: e.target.value,
                    });
                  }}
                  placeholder="Sonic 2, Yes Day"
                />
              </Field>
              <div
                className="flex flex-align-center gap-8"
                style={{ marginTop: 12 }}
              >
                <button
                  className="donate-button"
                  disabled={donationState.movieCount === 1}
                  onClick={() => {
                    setDonationState({
                      ...donationState,
                      movieCount: donationState.movieCount - 1,
                    });
                  }}
                >
                  <img src={imgBlob(ImageEnum.subtract)} alt="Substract 1" />
                </button>
                {donationState.movieCount}
                <button
                  className="donate-button"
                  onClick={() => {
                    setDonationState({
                      ...donationState,
                      movieCount: donationState.movieCount + 1,
                    });
                  }}
                >
                  <img src={imgBlob(ImageEnum.add)} alt="Add 1" />
                </button>
              </div>
            </div>
            <PlatformPicker
              language={donationState.language}
              setLanguage={(value) =>
                setDonationState({
                  ...donationState,
                  language: value,
                })
              }
              streamer={donationState.streamer}
              setStreamer={(value) =>
                setDonationState({
                  ...donationState,
                  streamer: value,
                })
              }
            />
          </>
        ) : null}

        {donationState.selected === "pick-episode" ? (
          <>
            <div className="form-header">Request information</div>
            <Field label="Series Name">
              <input
                type="text"
                value={donationState.seriesName}
                placeholder="Stranger Things"
                onChange={(e) => {
                  setDonationState({
                    ...donationState,
                    seriesName: e.target.value,
                  });
                }}
              />
            </Field>
            <div className="flex gap-8 w100 season-picker">
              <Field label="Season">
                <input
                  type="number"
                  placeholder="1"
                  value={donationState.seasonNumber}
                  onChange={(e) => {
                    setDonationState({
                      ...donationState,
                      seasonNumber: e.target.value,
                    });
                  }}
                />
              </Field>
              <Field label="Episode(s)">
                <input
                  type="text"
                  placeholder="1, 2, 3"
                  value={donationState.episodeNumbers}
                  onChange={(e) => {
                    setDonationState({
                      ...donationState,
                      episodeNumbers: e.target.value,
                    });
                  }}
                />
              </Field>
              <div className="flex flex-align-center" style={{ marginTop: 12 }}>
                <button
                  className="donate-button"
                  disabled={donationState.episodeCount === 1}
                  onClick={() => {
                    setDonationState({
                      ...donationState,
                      episodeCount: donationState.episodeCount - 1,
                    });
                  }}
                >
                  <img src={imgBlob(ImageEnum.subtract)} alt="Substract 1" />
                </button>
                {donationState.episodeCount}
                <button
                  className="donate-button"
                  onClick={() => {
                    setDonationState({
                      ...donationState,
                      episodeCount: donationState.episodeCount + 1,
                    });
                  }}
                >
                  <img src={imgBlob(ImageEnum.add)} alt="Add 1" />
                </button>
              </div>
            </div>
            <PlatformPicker
              language={donationState.language}
              setLanguage={(value) =>
                setDonationState({
                  ...donationState,
                  language: value,
                })
              }
              streamer={donationState.streamer}
              setStreamer={(value) =>
                setDonationState({
                  ...donationState,
                  streamer: value,
                })
              }
            />
          </>
        ) : null}

        {donationState.selected === "custom" ? (
          <>
            <div className="form-header">Custom amount</div>
            <Field label="Custom Amount">
              <input
                type="number"
                value={donationState.customAmount}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setDonationState({
                      ...donationState,
                      customAmount: undefined,
                    });
                  } else {
                    setDonationState({
                      ...donationState,
                      customAmount: parseFloat(e.target.value),
                    });
                  }
                }}
              />
            </Field>
          </>
        ) : null}

        <Payment payload={payload} setPayload={setPayload} />
      </div>
    </Container>
  );
};

export default Donate;
