import React, { Component } from "react";
import styled from "styled-components";
import { component } from "../../component";
import { cmsFields } from "../../cmsFields";
import {
  desktopBreakpoint,
  mobileBreakpoint,
  innerWidth,
} from "../../styleBits";
import { Button, ButtonTheme } from "../Button";
import { cms } from "../../cms";
import { ImageEnum, imgBlob } from "../../cssImg";

@component
export class CTA extends Component {
  static styles = styled.div`
    .innerCont {
      margin: 0 auto;
      ${desktopBreakpoint} {
        max-width: ${innerWidth};
        padding-bottom: 128px;
      }

      ${mobileBreakpoint} {
        padding: 0 16px 80px;
      }

      .title {
        color: var(--title);
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 72px;
        line-height: 80px;
        text-align: center;

        ${desktopBreakpoint} {
          margin-top: 128px;
          margin-bottom: 32px;
        }

        ${mobileBreakpoint} {
          margin-top: 7px;
          margin-bottom: 16px;
          font-size: 48px;
          line-height: 64px;
        }

        @media (max-width: 320px) {
          font-size: 42px;
          line-height: 1.4;
        }

        .cta-logo-container {
          display: inline-flex;
          align-items: center;
          gap: 20px;
          margin-right: 20px;
          width: 351px;
          position: relative;

          .fingers {
            height: 96px;
            width: 96px;
            filter: var(--logo-fingers-filter);
            position: absolute;
            top: -70px;
          }

          .logo-text {
            width: 235px;
            height: 72px;
            filter: var(--logo-text-filter);
            position: absolute;
            top: -53px;
            left: 116px;
          }

          ${mobileBreakpoint} {
            gap: 8px;
            width: auto;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .fingers {
              position: static;
              height: 55px;
              width: 55px;
            }

            .logo-text {
              position: static;
              width: 144px;
              height: 44px;
            }
          }
        }
      }

      .subtitle {
        color: var(--subtitle);
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        display: block;
        ${desktopBreakpoint} {
          width: 550px;
        }
        margin: 0 auto;
      }
    }

    .headerInner {
      display: flex;
      flex-direction: column;
      align-items: center;

      ${Button} {
        margin-top: 32px;
      }
    }
  `;

  render() {
    return (
      <div className="innerCont">
        <div className="headerInner">
          <h1 className="title">
            <div className="cta-logo-container">
              <img
                className="fingers"
                src={imgBlob(ImageEnum.logoFingers)}
                alt="SignUp Fingers Logo"
              />
              <img
                className="logo-text"
                src={imgBlob(ImageEnum.logoText)}
                alt="SignUp"
              />
            </div>
            is revolutionizing media accessibility for the Deaf community.
          </h1>
          <p className="subtitle">{cms(cmsFields.Header_Subtitle)}</p>
          <Button
            onClick={() => {
              gtag("event", "Install Click");
            }}
            newWindow
            url="https://chrome.google.com/webstore/detail/signup-sign-language-for/gbllbjbhbafgdcolenjhdoabdjjbjoom"
            text="Install the SignUp Chrome extension"
            theme={ButtonTheme.primary}
          />
        </div>
      </div>
    );
  }
}
