import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { API_URL } from "../api";

const useStripePromise = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  return stripePromise;
};

export default useStripePromise;
