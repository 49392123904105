import ReactDOM from "react-dom";
import { ImageEnum, imgBlob } from "../cssImg";
import styled from "styled-components";
import React, { PropsWithChildren } from "react";

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  .modal {
    border-radius: 16px;
    overflow: hidden;

    header {
      background: var(--alternative-section-bg);
      color: var(--alternative-text-color);
      padding: 16px;
      display: flex;
      gap: 16px;
      align-items: center;

      .fingers {
        height: 40px;
        width: 40px;
        filter: invert(1);
      }

      h2 {
        flex-grow: 1;
      }

      .close {
        border: 1px solid transparent;
        border-radius: 50px;
        cursor: pointer;
        &:hover {
          border-color: white;
        }
      }
    }
    section {
      background: var(--surface-bg-100);
      color: var(--icons-text-blue-primary);
      padding: 24px 16px;
    }
  }
`;
export function Modal(
  props: PropsWithChildren<{ title?: string; onClose?: { (): void } }>
) {
  return ReactDOM.createPortal(
    <StyledModal aria-modal="true">
      <div className="modal">
        <header>
          <img
            className="fingers"
            src={imgBlob(ImageEnum.logoFingers)}
            alt="SignUp Fingers Logo"
          />
          <h2>{props.title}</h2>
          <img
            onClick={props.onClose}
            className="close"
            src={imgBlob(ImageEnum.close)}
            alt="Close"
          />
        </header>
        <section>{props.children}</section>
      </div>
    </StyledModal>,
    document.querySelector("body")
  );
}
