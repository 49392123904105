import React from "react";
import styled from "styled-components";
import { Button, ButtonTheme } from "./Button";

const PreviewContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #7d3c003d;
`;

const Preview = styled.div`
  max-height: 250px;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: var(--testimonial-fade);
  }
`;

const ReadMoreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const StoryPreview = ({ children }) => {
  return (
    <PreviewContainer>
      <Preview>{children}</Preview>
      <ReadMoreButton>
        <Button theme={ButtonTheme.light_outline} text="Read More" />
      </ReadMoreButton>
    </PreviewContainer>
  );
};

export default StoryPreview;
